
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
// import { useRouter, useRoute } from 'vue-router';
import MainLayout from './layout/DefaultLayout.vue';
// import MainLayout from './layout/MainLayout.vue';
import DashboardLayout from './layout/DashboardLayout.vue';
import MasterLayout from './layout/MasterLayout.vue';

export default defineComponent({
  components: {
    MainLayout,
    DashboardLayout,
    MasterLayout,
  },
  // methods:{
  //   workWithStore(){
  //     this.$store.dispatch()
  //   }
  // },
  setup() {
    // const router = useRouter();
    const route = useRoute();
    return {
      layout: computed(() => route.meta.layout),
      title: computed(() => route.meta.title),
      menu: computed(() => route.meta.menu),
    };
  },
});

