/* eslint-disable */
import { createApp } from 'vue';

// import dotenv from 'dotenv';
// import { cors } from 'cors';
import './registerServiceWorker';
// import dotenv from 'dotenv';
// eslint-disable
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    fa0,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    fa8,
    fa9,
    faFillDrip,
    faArrowsToCircle,
    faCircleChevronRight,
    faChevronCircleRight,
    faAt,
    faTrashCan,
    faTrashAlt,
    faTextHeight,
    faUserXmark,
    faUserTimes,
    faStethoscope,
    faMessage,
    faCommentAlt,
    faInfo,
    faDownLeftAndUpRightToCenter,
    faCompressAlt,
    faExplosion,
    faFileLines,
    faFileAlt,
    faFileText,
    faWaveSquare,
    faRing,
    faBuildingUn,
    faDiceThree,
    faCalendarDays,
    faCalendarAlt,
    faAnchorCircleCheck,
    faBuildingCircleArrowRight,
    faVolleyball,
    faVolleyballBall,
    faArrowsUpToLine,
    faSortDown,
    faSortDesc,
    faCircleMinus,
    faMinusCircle,
    faDoorOpen,
    faRightFromBracket,
    faSignOutAlt,
    faAtom,
    faSoap,
    faIcons,
    faHeartMusicCameraBolt,
    faMicrophoneLinesSlash,
    faMicrophoneAltSlash,
    faBridgeCircleCheck,
    faPumpMedical,
    faFingerprint,
    faHandPointRight,
    faMagnifyingGlassLocation,
    faSearchLocation,
    faForwardStep,
    faStepForward,
    faFaceSmileBeam,
    faSmileBeam,
    faFlagCheckered,
    faFootball,
    faFootballBall,
    faSchoolCircleExclamation,
    faCrop,
    faAnglesDown,
    faAngleDoubleDown,
    faUsersRectangle,
    faPeopleRoof,
    faPeopleLine,
    faBeerMugEmpty,
    faBeer,
    faDiagramPredecessor,
    faArrowUpLong,
    faLongArrowUp,
    faFireFlameSimple,
    faBurn,
    faPerson,
    faMale,
    faLaptop,
    faFileCsv,
    faMenorah,
    faTruckPlane,
    faRecordVinyl,
    faFaceGrinStars,
    faGrinStars,
    faBong,
    faSpaghettiMonsterFlying,
    faPastafarianism,
    faArrowDownUpAcrossLine,
    faSpoon,
    faUtensilSpoon,
    faJarWheat,
    faEnvelopesBulk,
    faMailBulk,
    faFileCircleExclamation,
    faCircleH,
    faHospitalSymbol,
    faPager,
    faAddressBook,
    faContactBook,
    faStrikethrough,
    faK,
    faLandmarkFlag,
    faPencil,
    faPencilAlt,
    faBackward,
    faCaretRight,
    faComments,
    faPaste,
    faFileClipboard,
    faCodePullRequest,
    faClipboardList,
    faTruckRampBox,
    faTruckLoading,
    faUserCheck,
    faVialVirus,
    faSheetPlastic,
    faBlog,
    faUserNinja,
    faPersonArrowUpFromLine,
    faScrollTorah,
    faTorah,
    faBroomBall,
    faQuidditch,
    faQuidditchBroomBall,
    faToggleOff,
    faBoxArchive,
    faArchive,
    faPersonDrowning,
    faArrowDown91,
    faSortNumericDesc,
    faSortNumericDownAlt,
    faFaceGrinTongueSquint,
    faGrinTongueSquint,
    faSprayCan,
    faTruckMonster,
    faW,
    faEarthAfrica,
    faGlobeAfrica,
    faRainbow,
    faCircleNotch,
    faTabletScreenButton,
    faTabletAlt,
    faPaw,
    faCloud,
    faTrowelBricks,
    faFaceFlushed,
    faFlushed,
    faHospitalUser,
    faTentArrowLeftRight,
    faGavel,
    faLegal,
    faBinoculars,
    faMicrophoneSlash,
    faBoxTissue,
    faMotorcycle,
    faBellConcierge,
    faConciergeBell,
    faPenRuler,
    faPencilRuler,
    faPeopleArrows,
    faPeopleArrowsLeftRight,
    faMarsAndVenusBurst,
    faSquareCaretRight,
    faCaretSquareRight,
    faScissors,
    faCut,
    faSunPlantWilt,
    faToiletsPortable,
    faHockeyPuck,
    faTable,
    faMagnifyingGlassArrowRight,
    faTachographDigital,
    faDigitalTachograph,
    faUsersSlash,
    faClover,
    faReply,
    faMailReply,
    faStarAndCrescent,
    faHouseFire,
    faSquareMinus,
    faMinusSquare,
    faHelicopter,
    faCompass,
    faSquareCaretDown,
    faCaretSquareDown,
    faFileCircleQuestion,
    faLaptopCode,
    faSwatchbook,
    faPrescriptionBottle,
    faBars,
    faNavicon,
    faPeopleGroup,
    faHourglassEnd,
    faHourglass3,
    faHeartCrack,
    faHeartBroken,
    faSquareUpRight,
    faExternalLinkSquareAlt,
    faFaceKissBeam,
    faKissBeam,
    faFilm,
    faRulerHorizontal,
    faPeopleRobbery,
    faLightbulb,
    faCaretLeft,
    faCircleExclamation,
    faExclamationCircle,
    faSchoolCircleXmark,
    faArrowRightFromBracket,
    faSignOut,
    faCircleChevronDown,
    faChevronCircleDown,
    faUnlockKeyhole,
    faUnlockAlt,
    faCloudShowersHeavy,
    faHeadphonesSimple,
    faHeadphonesAlt,
    faSitemap,
    faCircleDollarToSlot,
    faDonate,
    faMemory,
    faRoadSpikes,
    faFireBurner,
    faFlag,
    faHanukiah,
    faFeather,
    faVolumeLow,
    faVolumeDown,
    faCommentSlash,
    faCloudSunRain,
    faCompress,
    faWheatAwn,
    faWheatAlt,
    faAnkh,
    faHandsHoldingChild,
    faAsterisk,
    faSquareCheck,
    faCheckSquare,
    faPesetaSign,
    faHeading,
    faHeader,
    faGhost,
    faList,
    faListSquares,
    faSquarePhoneFlip,
    faPhoneSquareAlt,
    faCartPlus,
    faGamepad,
    faCircleDot,
    faDotCircle,
    faFaceDizzy,
    faDizzy,
    faEgg,
    faHouseMedicalCircleXmark,
    faCampground,
    faFolderPlus,
    faFutbol,
    faFutbolBall,
    faSoccerBall,
    faPaintbrush,
    faPaintBrush,
    faLock,
    faGasPump,
    faHotTubPerson,
    faHotTub,
    faMapLocation,
    faMapMarked,
    faHouseFloodWater,
    faTree,
    faBridgeLock,
    faSackDollar,
    faPenToSquare,
    faEdit,
    faCarSide,
    faShareNodes,
    faShareAlt,
    faHeartCircleMinus,
    faHourglassHalf,
    faHourglass2,
    faMicroscope,
    faSink,
    faBagShopping,
    faShoppingBag,
    faArrowDownZA,
    faSortAlphaDesc,
    faSortAlphaDownAlt,
    faMitten,
    faPersonRays,
    faUsers,
    faEyeSlash,
    faFlaskVial,
    faHand,
    faHandPaper,
    faOm,
    faWorm,
    faHouseCircleXmark,
    faPlug,
    faChevronUp,
    faHandSpock,
    faStopwatch,
    faFaceKiss,
    faKiss,
    faBridgeCircleXmark,
    faFaceGrinTongue,
    faGrinTongue,
    faChessBishop,
    faFaceGrinWink,
    faGrinWink,
    faEarDeaf,
    faDeaf,
    faDeafness,
    faHardOfHearing,
    faRoadCircleCheck,
    faDiceFive,
    faSquareRss,
    faRssSquare,
    faLandMineOn,
    faICursor,
    faStamp,
    faStairs,
    faI,
    faHryvniaSign,
    faHryvnia,
    faPills,
    faFaceGrinWide,
    faGrinAlt,
    faTooth,
    faV,
    faBangladeshiTakaSign,
    faBicycle,
    faStaffSnake,
    faRodAsclepius,
    faRodSnake,
    faStaffAesculapius,
    faHeadSideCoughSlash,
    faTruckMedical,
    faAmbulance,
    faWheatAwnCircleExclamation,
    faSnowman,
    faMortarPestle,
    faRoadBarrier,
    faSchool,
    faIgloo,
    faJoint,
    faAngleRight,
    faHorse,
    faQ,
    faG,
    faNotesMedical,
    faTemperatureHalf,
    faTemperature2,
    faThermometer2,
    faThermometerHalf,
    faDongSign,
    faCapsules,
    faPooStorm,
    faPooBolt,
    faFaceFrownOpen,
    faFrownOpen,
    faHandPointUp,
    faMoneyBill,
    faBookmark,
    faAlignJustify,
    faUmbrellaBeach,
    faHelmetUn,
    faBullseye,
    faBacon,
    faHandPointDown,
    faArrowUpFromBracket,
    faFolder,
    faFolderBlank,
    faFileWaveform,
    faFileMedicalAlt,
    faRadiation,
    faChartSimple,
    faMarsStroke,
    faVial,
    faGauge,
    faDashboard,
    faGaugeMed,
    faTachometerAltAverage,
    faWandMagicSparkles,
    faMagicWandSparkles,
    faE,
    faPenClip,
    faPenAlt,
    faBridgeCircleExclamation,
    faUser,
    faSchoolCircleCheck,
    faDumpster,
    faVanShuttle,
    faShuttleVan,
    faBuildingUser,
    faSquareCaretLeft,
    faCaretSquareLeft,
    faHighlighter,
    faKey,
    faBullhorn,
    faGlobe,
    faSynagogue,
    faPersonHalfDress,
    faRoadBridge,
    faLocationArrow,
    faC,
    faTabletButton,
    faBuildingLock,
    faPizzaSlice,
    faMoneyBillWave,
    faChartArea,
    faAreaChart,
    faHouseFlag,
    faPersonCircleMinus,
    faBan,
    faCancel,
    faCameraRotate,
    faSprayCanSparkles,
    faAirFreshener,
    faStar,
    faRepeat,
    faCross,
    faBox,
    faVenusMars,
    faArrowPointer,
    faMousePointer,
    faMaximize,
    faExpandArrowsAlt,
    faChargingStation,
    faShapes,
    faTriangleCircleSquare,
    faShuffle,
    faRandom,
    faPersonRunning,
    faRunning,
    faMobileRetro,
    faGripLinesVertical,
    faSpider,
    faHandsBound,
    faFileInvoiceDollar,
    faPlaneCircleExclamation,
    faXRay,
    faSpellCheck,
    faSlash,
    faComputerMouse,
    faMouse,
    faArrowRightToBracket,
    faSignIn,
    faShopSlash,
    faStoreAltSlash,
    faServer,
    faVirusCovidSlash,
    faShopLock,
    faHourglassStart,
    faHourglass1,
    faBlenderPhone,
    faBuildingWheat,
    faPersonBreastfeeding,
    faRightToBracket,
    faSignInAlt,
    faVenus,
    faPassport,
    faHeartPulse,
    faHeartbeat,
    faPeopleCarryBox,
    faPeopleCarry,
    faTemperatureHigh,
    faMicrochip,
    faCrown,
    faWeightHanging,
    faXmarksLines,
    faFilePrescription,
    faWeightScale,
    faWeight,
    faUserGroup,
    faUserFriends,
    faArrowUpAZ,
    faSortAlphaUp,
    faChessKnight,
    faFaceLaughSquint,
    faLaughSquint,
    faWheelchair,
    faCircleArrowUp,
    faArrowCircleUp,
    faToggleOn,
    faPersonWalking,
    faWalking,
    faL,
    faFire,
    faBedPulse,
    faProcedures,
    faShuttleSpace,
    faSpaceShuttle,
    faFaceLaugh,
    faLaugh,
    faFolderOpen,
    faHeartCirclePlus,
    faCodeFork,
    faCity,
    faMicrophoneLines,
    faMicrophoneAlt,
    faPepperHot,
    faUnlock,
    faColonSign,
    faHeadset,
    faStoreSlash,
    faRoadCircleXmark,
    faUserMinus,
    faMarsStrokeUp,
    faMarsStrokeV,
    faChampagneGlasses,
    faGlassCheers,
    faClipboard,
    faHouseCircleExclamation,
    faFileArrowUp,
    faFileUpload,
    faWifi,
    faWifi3,
    faWifiStrong,
    faBath,
    faBathtub,
    faUnderline,
    faUserPen,
    faUserEdit,
    faSignature,
    faStroopwafel,
    faBold,
    faAnchorLock,
    faBuildingNgo,
    faManatSign,
    faNotEqual,
    faBorderTopLeft,
    faBorderStyle,
    faMapLocationDot,
    faMapMarkedAlt,
    faJedi,
    faSquarePollVertical,
    faPoll,
    faMugHot,
    faCarBattery,
    faBatteryCar,
    faGift,
    faDiceTwo,
    faChessQueen,
    faGlasses,
    faChessBoard,
    faBuildingCircleCheck,
    faPersonChalkboard,
    faMarsStrokeRight,
    faMarsStrokeH,
    faHandBackFist,
    faHandRock,
    faSquareCaretUp,
    faCaretSquareUp,
    faCloudShowersWater,
    faChartBar,
    faBarChart,
    faHandsBubbles,
    faHandsWash,
    faLessThanEqual,
    faTrain,
    faEyeLowVision,
    faLowVision,
    faCrow,
    faSailboat,
    faWindowRestore,
    faSquarePlus,
    faPlusSquare,
    faToriiGate,
    faFrog,
    faBucket,
    faImage,
    faMicrophone,
    faCow,
    faCaretUp,
    faScrewdriver,
    faFolderClosed,
    faHouseTsunami,
    faSquareNfi,
    faArrowUpFromGroundWater,
    faMartiniGlass,
    faGlassMartiniAlt,
    faRotateLeft,
    faRotateBack,
    faRotateBackward,
    faUndoAlt,
    faTableColumns,
    faColumns,
    faLemon,
    faHeadSideMask,
    faHandshake,
    faGem,
    faDolly,
    faDollyBox,
    faSmoking,
    faMinimize,
    faCompressArrowsAlt,
    faMonument,
    faSnowplow,
    faAnglesRight,
    faAngleDoubleRight,
    faCannabis,
    faCirclePlay,
    faPlayCircle,
    faTablets,
    faEthernet,
    faEuroSign,
    faEur,
    faEuro,
    faChair,
    faCircleCheck,
    faCheckCircle,
    faCircleStop,
    faStopCircle,
    faCompassDrafting,
    faDraftingCompass,
    faPlateWheat,
    faIcicles,
    faPersonShelter,
    faNeuter,
    faIdBadge,
    faMarker,
    faFaceLaughBeam,
    faLaughBeam,
    faHelicopterSymbol,
    faUniversalAccess,
    faCircleChevronUp,
    faChevronCircleUp,
    faLariSign,
    faVolcano,
    faPersonWalkingDashedLineArrowRight,
    faSterlingSign,
    faGbp,
    faPoundSign,
    faViruses,
    faSquarePersonConfined,
    faUserTie,
    faArrowDownLong,
    faLongArrowDown,
    faTentArrowDownToLine,
    faCertificate,
    faReplyAll,
    faMailReplyAll,
    faSuitcase,
    faPersonSkating,
    faSkating,
    faFilterCircleDollar,
    faFunnelDollar,
    faCameraRetro,
    faCircleArrowDown,
    faArrowCircleDown,
    faFileImport,
    faArrowRightToFile,
    faSquareArrowUpRight,
    faExternalLinkSquare,
    faBoxOpen,
    faScroll,
    faSpa,
    faLocationPinLock,
    faPause,
    faHillAvalanche,
    faTemperatureEmpty,
    faTemperature0,
    faThermometer0,
    faThermometerEmpty,
    faBomb,
    faRegistered,
    faAddressCard,
    faContactCard,
    faVcard,
    faScaleUnbalancedFlip,
    faBalanceScaleRight,
    faSubscript,
    faDiamondTurnRight,
    faDirections,
    faBurst,
    faHouseLaptop,
    faLaptopHouse,
    faFaceTired,
    faTired,
    faMoneyBills,
    faSmog,
    faCrutch,
    faFontAwesome,
    faFontAwesomeFlag,
    faFontAwesomeLogoFull,
    faCloudArrowUp,
    faCloudUpload,
    faCloudUploadAlt,
    faPalette,
    faArrowsTurnRight,
    faVest,
    faFerry,
    faArrowsDownToPeople,
    faSeedling,
    faSprout,
    faLeftRight,
    faArrowsAltH,
    faBoxesPacking,
    faCircleArrowLeft,
    faArrowCircleLeft,
    faGroupArrowsRotate,
    faBowlFood,
    faCandyCane,
    faArrowDownWideShort,
    faSortAmountAsc,
    faSortAmountDown,
    faCloudBolt,
    faThunderstorm,
    faTextSlash,
    faRemoveFormat,
    faFaceSmileWink,
    faSmileWink,
    faFileWord,
    faFilePowerpoint,
    faArrowsLeftRight,
    faArrowsH,
    faHouseLock,
    faCloudArrowDown,
    faCloudDownload,
    faCloudDownloadAlt,
    faChildren,
    faChalkboard,
    faBlackboard,
    faUserLargeSlash,
    faUserAltSlash,
    faEnvelopeOpen,
    faHandshakeSimpleSlash,
    faHandshakeAltSlash,
    faMattressPillow,
    faGuaraniSign,
    faArrowsRotate,
    faRefresh,
    faSync,
    faFireExtinguisher,
    faCruzeiroSign,
    faGreaterThanEqual,
    faShieldHalved,
    faShieldAlt,
    faBookAtlas,
    faAtlas,
    faVirus,
    faEnvelopeCircleCheck,
    faLayerGroup,
    faArrowsToDot,
    faArchway,
    faHeartCircleCheck,
    faHouseChimneyCrack,
    faHouseDamage,
    faFileZipper,
    faFileArchive,
    faSquare,
    faMartiniGlassEmpty,
    faGlassMartini,
    faCouch,
    faCediSign,
    faItalic,
    faChurch,
    faCommentsDollar,
    faDemocrat,
    faZ,
    faPersonSkiing,
    faSkiing,
    faRoadLock,
    faA,
    faTemperatureArrowDown,
    faTemperatureDown,
    faFeatherPointed,
    faFeatherAlt,
    faP,
    faSnowflake,
    faNewspaper,
    faRectangleAd,
    faAd,
    faCircleArrowRight,
    faArrowCircleRight,
    faFilterCircleXmark,
    faLocust,
    faSort,
    faUnsorted,
    faListOl,
    faList12,
    faListNumeric,
    faPersonDressBurst,
    faMoneyCheckDollar,
    faMoneyCheckAlt,
    faVectorSquare,
    faBreadSlice,
    faLanguage,
    faFaceKissWinkHeart,
    faKissWinkHeart,
    faFilter,
    faQuestion,
    faFileSignature,
    faUpDownLeftRight,
    faArrowsAlt,
    faHouseChimneyUser,
    faHandHoldingHeart,
    faPuzzlePiece,
    faMoneyCheck,
    faStarHalfStroke,
    faStarHalfAlt,
    faCode,
    faWhiskeyGlass,
    faGlassWhiskey,
    faBuildingCircleExclamation,
    faMagnifyingGlassChart,
    faArrowUpRightFromSquare,
    faExternalLink,
    faCubesStacked,
    faWonSign,
    faKrw,
    faWon,
    faVirusCovid,
    faAustralSign,
    faF,
    faLeaf,
    faRoad,
    faTaxi,
    faCab,
    faPersonCirclePlus,
    faChartPie,
    faPieChart,
    faBoltLightning,
    faSackXmark,
    faFileExcel,
    faFileContract,
    faFishFins,
    faBuildingFlag,
    faFaceGrinBeam,
    faGrinBeam,
    faObjectUngroup,
    faPoop,
    faLocationPin,
    faMapMarker,
    faKaaba,
    faToiletPaper,
    faHelmetSafety,
    faHardHat,
    faHatHard,
    faEject,
    faCircleRight,
    faArrowAltCircleRight,
    faPlaneCircleCheck,
    faFaceRollingEyes,
    faMehRollingEyes,
    faObjectGroup,
    faChartLine,
    faLineChart,
    faMaskVentilator,
    faArrowRight,
    faSignsPost,
    faMapSigns,
    faCashRegister,
    faPersonCircleQuestion,
    faH,
    faTarp,
    faScrewdriverWrench,
    faTools,
    faArrowsToEye,
    faPlugCircleBolt,
    faHeart,
    faMarsAndVenus,
    faHouseUser,
    faHomeUser,
    faDumpsterFire,
    faHouseCrack,
    faMartiniGlassCitrus,
    faCocktail,
    faFaceSurprise,
    faSurprise,
    faBottleWater,
    faCirclePause,
    faPauseCircle,
    faToiletPaperSlash,
    faAppleWhole,
    faAppleAlt,
    faKitchenSet,
    faR,
    faTemperatureQuarter,
    faTemperature1,
    faThermometer1,
    faThermometerQuarter,
    faCube,
    faBitcoinSign,
    faShieldDog,
    faSolarPanel,
    faLockOpen,
    faElevator,
    faMoneyBillTransfer,
    faMoneyBillTrendUp,
    faHouseFloodWaterCircleArrowRight,
    faSquarePollHorizontal,
    faPollH,
    faCircle,
    faBackwardFast,
    faFastBackward,
    faRecycle,
    faUserAstronaut,
    faPlaneSlash,
    faTrademark,
    faBasketball,
    faBasketballBall,
    faSatelliteDish,
    faCircleUp,
    faArrowAltCircleUp,
    faMobileScreenButton,
    faMobileAlt,
    faVolumeHigh,
    faVolumeUp,
    faUsersRays,
    faWallet,
    faClipboardCheck,
    faFileAudio,
    faBurger,
    faHamburger,
    faWrench,
    faBugs,
    faRupeeSign,
    faRupee,
    faFileImage,
    faCircleQuestion,
    faQuestionCircle,
    faPlaneDeparture,
    faHandshakeSlash,
    faBookBookmark,
    faCodeBranch,
    faHatCowboy,
    faBridge,
    faPhoneFlip,
    faPhoneAlt,
    faTruckFront,
    faCat,
    faAnchorCircleExclamation,
    faTruckField,
    faRoute,
    faClipboardQuestion,
    faPanorama,
    faCommentMedical,
    faTeethOpen,
    faFileCircleMinus,
    faTags,
    faWineGlass,
    faForwardFast,
    faFastForward,
    faFaceMehBlank,
    faMehBlank,
    faSquareParking,
    faParking,
    faHouseSignal,
    faBarsProgress,
    faTasksAlt,
    faFaucetDrip,
    faCartFlatbed,
    faDollyFlatbed,
    faBanSmoking,
    faSmokingBan,
    faTerminal,
    faMobileButton,
    faHouseMedicalFlag,
    faBasketShopping,
    faShoppingBasket,
    faTape,
    faBusSimple,
    faBusAlt,
    faEye,
    faFaceSadCry,
    faSadCry,
    faAudioDescription,
    faPersonMilitaryToPerson,
    faFileShield,
    faUserSlash,
    faPen,
    faTowerObservation,
    faFileCode,
    faSignal,
    faSignal5,
    faSignalPerfect,
    faBus,
    faHeartCircleXmark,
    faHouseChimney,
    faHomeLg,
    faWindowMaximize,
    faFaceFrown,
    faFrown,
    faPrescription,
    faShop,
    faStoreAlt,
    faFloppyDisk,
    faSave,
    faVihara,
    faScaleUnbalanced,
    faBalanceScaleLeft,
    faSortUp,
    faSortAsc,
    faCommentDots,
    faCommenting,
    faPlantWilt,
    faDiamond,
    faFaceGrinSquint,
    faGrinSquint,
    faHandHoldingDollar,
    faHandHoldingUsd,
    faBacterium,
    faHandPointer,
    faDrumSteelpan,
    faHandScissors,
    faHandsPraying,
    faPrayingHands,
    faArrowRotateRight,
    faArrowRightRotate,
    faArrowRotateForward,
    faRedo,
    faBiohazard,
    faLocationCrosshairs,
    faLocation,
    faMarsDouble,
    faChildDress,
    faUsersBetweenLines,
    faLungsVirus,
    faFaceGrinTears,
    faGrinTears,
    faPhone,
    faCalendarXmark,
    faCalendarTimes,
    faChildReaching,
    faHeadSideVirus,
    faUserGear,
    faUserCog,
    faArrowUp19,
    faSortNumericUp,
    faDoorClosed,
    faShieldVirus,
    faDiceSix,
    faMosquitoNet,
    faBridgeWater,
    faPersonBooth,
    faTextWidth,
    faHatWizard,
    faPenFancy,
    faPersonDigging,
    faDigging,
    faTrash,
    faGaugeSimple,
    faGaugeSimpleMed,
    faTachometerAverage,
    faBookMedical,
    faPoo,
    faQuoteRight,
    faQuoteRightAlt,
    faShirt,
    faTShirt,
    faTshirt,
    faCubes,
    faDivide,
    faTengeSign,
    faTenge,
    faHeadphones,
    faHandsHolding,
    faHandsClapping,
    faRepublican,
    faArrowLeft,
    faPersonCircleXmark,
    faRuler,
    faAlignLeft,
    faDiceD6,
    faRestroom,
    faJ,
    faUsersViewfinder,
    faFileVideo,
    faUpRightFromSquare,
    faExternalLinkAlt,
    faTableCells,
    faTh,
    faFilePdf,
    faBookBible,
    faBible,
    faO,
    faSuitcaseMedical,
    faMedkit,
    faUserSecret,
    faOtter,
    faPersonDress,
    faFemale,
    faCommentDollar,
    faBusinessTime,
    faBriefcaseClock,
    faTableCellsLarge,
    faThLarge,
    faBookTanakh,
    faTanakh,
    faPhoneVolume,
    faVolumeControlPhone,
    faHatCowboySide,
    faClipboardUser,
    faChild,
    faLiraSign,
    faSatellite,
    faPlaneLock,
    faTag,
    faComment,
    faCakeCandles,
    faBirthdayCake,
    faCake,
    faEnvelope,
    faAnglesUp,
    faAngleDoubleUp,
    faPaperclip,
    faArrowRightToCity,
    faRibbon,
    faLungs,
    faArrowUp91,
    faSortNumericUpAlt,
    faLitecoinSign,
    faBorderNone,
    faCircleNodes,
    faParachuteBox,
    faIndent,
    faTruckFieldUn,
    faHourglass,
    faHourglassEmpty,
    faMountain,
    faUserDoctor,
    faUserMd,
    faCircleInfo,
    faInfoCircle,
    faCloudMeatball,
    faCamera,
    faCameraAlt,
    faSquareVirus,
    faMeteor,
    faCarOn,
    faSleigh,
    faArrowDown19,
    faSortNumericAsc,
    faSortNumericDown,
    faHandHoldingDroplet,
    faHandHoldingWater,
    faWater,
    faCalendarCheck,
    faBraille,
    faPrescriptionBottleMedical,
    faPrescriptionBottleAlt,
    faLandmark,
    faTruck,
    faCrosshairs,
    faPersonCane,
    faTent,
    faVestPatches,
    faCheckDouble,
    faArrowDownAZ,
    faSortAlphaAsc,
    faSortAlphaDown,
    faMoneyBillWheat,
    faCookie,
    faArrowRotateLeft,
    faArrowLeftRotate,
    faArrowRotateBack,
    faArrowRotateBackward,
    faUndo,
    faHardDrive,
    faHdd,
    faFaceGrinSquintTears,
    faGrinSquintTears,
    faDumbbell,
    faRectangleList,
    faListAlt,
    faTarpDroplet,
    faHouseMedicalCircleCheck,
    faPersonSkiingNordic,
    faSkiingNordic,
    faCalendarPlus,
    faPlaneArrival,
    faCircleLeft,
    faArrowAltCircleLeft,
    faTrainSubway,
    faSubway,
    faChartGantt,
    faIndianRupeeSign,
    faIndianRupee,
    faInr,
    faCropSimple,
    faCropAlt,
    faMoneyBill1,
    faMoneyBillAlt,
    faLeftLong,
    faLongArrowAltLeft,
    faDna,
    faVirusSlash,
    faMinus,
    faSubtract,
    faChess,
    faArrowLeftLong,
    faLongArrowLeft,
    faPlugCircleCheck,
    faStreetView,
    faFrancSign,
    faVolumeOff,
    faHandsAslInterpreting,
    faAmericanSignLanguageInterpreting,
    faAslInterpreting,
    faHandsAmericanSignLanguageInterpreting,
    faGear,
    faCog,
    faDropletSlash,
    faTintSlash,
    faMosque,
    faMosquito,
    faStarOfDavid,
    faPersonMilitaryRifle,
    faCartShopping,
    faShoppingCart,
    faVials,
    faPlugCirclePlus,
    faPlaceOfWorship,
    faGripVertical,
    faArrowTurnUp,
    faLevelUp,
    faU,
    faSquareRootVariable,
    faSquareRootAlt,
    faClock,
    faClockFour,
    faBackwardStep,
    faStepBackward,
    faPallet,
    faFaucet,
    faBaseballBatBall,
    faS,
    faTimeline,
    faKeyboard,
    faCaretDown,
    faHouseChimneyMedical,
    faClinicMedical,
    faTemperatureThreeQuarters,
    faTemperature3,
    faThermometer3,
    faThermometerThreeQuarters,
    faMobileScreen,
    faMobileAndroidAlt,
    faPlaneUp,
    faPiggyBank,
    faBatteryHalf,
    faBattery3,
    faMountainCity,
    faCoins,
    faKhanda,
    faSliders,
    faSlidersH,
    faFolderTree,
    faNetworkWired,
    faMapPin,
    faHamsa,
    faCentSign,
    faFlask,
    faPersonPregnant,
    faWandSparkles,
    faEllipsisVertical,
    faEllipsisV,
    faTicket,
    faPowerOff,
    faRightLong,
    faLongArrowAltRight,
    faFlagUsa,
    faLaptopFile,
    faTty,
    faTeletype,
    faDiagramNext,
    faPersonRifle,
    faHouseMedicalCircleExclamation,
    faClosedCaptioning,
    faPersonHiking,
    faHiking,
    faVenusDouble,
    faImages,
    faCalculator,
    faPeoplePulling,
    faN,
    faCableCar,
    faTram,
    faCloudRain,
    faBuildingCircleXmark,
    faShip,
    faArrowsDownToLine,
    faDownload,
    faFaceGrin,
    faGrin,
    faDeleteLeft,
    faBackspace,
    faEyeDropper,
    faEyeDropperEmpty,
    faEyedropper,
    faFileCircleCheck,
    faForward,
    faMobile,
    faMobileAndroid,
    faMobilePhone,
    faFaceMeh,
    faMeh,
    faAlignCenter,
    faBookSkull,
    faBookDead,
    faIdCard,
    faDriversLicense,
    faOutdent,
    faDedent,
    faHeartCircleExclamation,
    faHouse,
    faHome,
    faHomeAlt,
    faHomeLgAlt,
    faCalendarWeek,
    faLaptopMedical,
    faB,
    faFileMedical,
    faDiceOne,
    faKiwiBird,
    faArrowRightArrowLeft,
    faExchange,
    faRotateRight,
    faRedoAlt,
    faRotateForward,
    faUtensils,
    faCutlery,
    faArrowUpWideShort,
    faSortAmountUp,
    faMillSign,
    faBowlRice,
    faSkull,
    faTowerBroadcast,
    faBroadcastTower,
    faTruckPickup,
    faUpLong,
    faLongArrowAltUp,
    faStop,
    faCodeMerge,
    faUpload,
    faHurricane,
    faMound,
    faToiletPortable,
    faCompactDisc,
    faFileArrowDown,
    faFileDownload,
    faCaravan,
    faShieldCat,
    faBolt,
    faZap,
    faGlassWater,
    faOilWell,
    faVault,
    faMars,
    faToilet,
    faPlaneCircleXmark,
    faYenSign,
    faCny,
    faJpy,
    faRmb,
    faYen,
    faRubleSign,
    faRouble,
    faRub,
    faRuble,
    faSun,
    faGuitar,
    faFaceLaughWink,
    faLaughWink,
    faHorseHead,
    faBoreHole,
    faIndustry,
    faCircleDown,
    faArrowAltCircleDown,
    faArrowsTurnToDots,
    faFlorinSign,
    faArrowDownShortWide,
    faSortAmountDesc,
    faSortAmountDownAlt,
    faLessThan,
    faAngleDown,
    faCarTunnel,
    faHeadSideCough,
    faGripLines,
    faThumbsDown,
    faUserLock,
    faArrowRightLong,
    faLongArrowRight,
    faAnchorCircleXmark,
    faEllipsis,
    faEllipsisH,
    faChessPawn,
    faKitMedical,
    faFirstAid,
    faPersonThroughWindow,
    faToolbox,
    faHandsHoldingCircle,
    faBug,
    faCreditCard,
    faCreditCardAlt,
    faCar,
    faAutomobile,
    faHandHoldingHand,
    faBookOpenReader,
    faBookReader,
    faMountainSun,
    faArrowsLeftRightToLine,
    faDiceD20,
    faTruckDroplet,
    faFileCircleXmark,
    faTemperatureArrowUp,
    faTemperatureUp,
    faMedal,
    faBed,
    faSquareH,
    faHSquare,
    faPodcast,
    faTemperatureFull,
    faTemperature4,
    faThermometer4,
    faThermometerFull,
    faBell,
    faSuperscript,
    faPlugCircleXmark,
    faStarOfLife,
    faPhoneSlash,
    faPaintRoller,
    faHandshakeAngle,
    faHandsHelping,
    faLocationDot,
    faMapMarkerAlt,
    faFile,
    faGreaterThan,
    faPersonSwimming,
    faSwimmer,
    faArrowDown,
    faDroplet,
    faTint,
    faEraser,
    faEarthAmericas,
    faEarth,
    faEarthAmerica,
    faGlobeAmericas,
    faPersonBurst,
    faDove,
    faBatteryEmpty,
    faBattery0,
    faSocks,
    faInbox,
    faSection,
    faGaugeHigh,
    faTachometerAlt,
    faTachometerAltFast,
    faEnvelopeOpenText,
    faHospital,
    faHospitalAlt,
    faHospitalWide,
    faWineBottle,
    faChessRook,
    faBarsStaggered,
    faReorder,
    faStream,
    faDharmachakra,
    faHotdog,
    faPersonWalkingWithCane,
    faBlind,
    faDrum,
    faIceCream,
    faHeartCircleBolt,
    faFax,
    faParagraph,
    faCheckToSlot,
    faVoteYea,
    faStarHalf,
    faBoxesStacked,
    faBoxes,
    faBoxesAlt,
    faLink,
    faChain,
    faEarListen,
    faAssistiveListeningSystems,
    faTreeCity,
    faPlay,
    faFont,
    faRupiahSign,
    faMagnifyingGlass,
    faSearch,
    faTableTennisPaddleBall,
    faPingPongPaddleBall,
    faTableTennis,
    faPersonDotsFromLine,
    faDiagnoses,
    faTrashCanArrowUp,
    faTrashRestoreAlt,
    faNairaSign,
    faCartArrowDown,
    faWalkieTalkie,
    faFilePen,
    faFileEdit,
    faReceipt,
    faSquarePen,
    faPenSquare,
    faPencilSquare,
    faSuitcaseRolling,
    faPersonCircleExclamation,
    faChevronDown,
    faBatteryFull,
    faBattery,
    faBattery5,
    faSkullCrossbones,
    faCodeCompare,
    faListUl,
    faListDots,
    faSchoolLock,
    faTowerCell,
    faDownLong,
    faLongArrowAltDown,
    faRankingStar,
    faChessKing,
    faPersonHarassing,
    faBrazilianRealSign,
    faLandmarkDome,
    faLandmarkAlt,
    faArrowUp,
    faTv,
    faTelevision,
    faTvAlt,
    faShrimp,
    faListCheck,
    faTasks,
    faJugDetergent,
    faCircleUser,
    faUserCircle,
    faUserShield,
    faWind,
    faCarBurst,
    faCarCrash,
    faY,
    faPersonSnowboarding,
    faSnowboarding,
    faTruckFast,
    faShippingFast,
    faFish,
    faUserGraduate,
    faCircleHalfStroke,
    faAdjust,
    faClapperboard,
    faCircleRadiation,
    faRadiationAlt,
    faBaseball,
    faBaseballBall,
    faJetFighterUp,
    faDiagramProject,
    faProjectDiagram,
    faCopy,
    faVolumeXmark,
    faVolumeMute,
    faVolumeTimes,
    faHandSparkles,
    faGrip,
    faGripHorizontal,
    faShareFromSquare,
    faShareSquare,
    faChildCombatant,
    faChildRifle,
    faGun,
    faSquarePhone,
    faPhoneSquare,
    faPlus,
    faAdd,
    faExpand,
    faComputer,
    faXmark,
    faClose,
    faMultiply,
    faRemove,
    faTimes,
    faArrowsUpDownLeftRight,
    faArrows,
    faChalkboardUser,
    faChalkboardTeacher,
    faPesoSign,
    faBuildingShield,
    faBaby,
    faUsersLine,
    faQuoteLeft,
    faQuoteLeftAlt,
    faTractor,
    faTrashArrowUp,
    faTrashRestore,
    faArrowDownUpLock,
    faLinesLeaning,
    faRulerCombined,
    faCopyright,
    faEquals,
    faBlender,
    faTeeth,
    faShekelSign,
    faIls,
    faShekel,
    faSheqel,
    faSheqelSign,
    faMap,
    faRocket,
    faPhotoFilm,
    faPhotoVideo,
    faFolderMinus,
    faStore,
    faArrowTrendUp,
    faPlugCircleMinus,
    faSignHanging,
    faSign,
    faBezierCurve,
    faBellSlash,
    faTablet,
    faTabletAndroid,
    faSchoolFlag,
    faFill,
    faAngleUp,
    faDrumstickBite,
    faHollyBerry,
    faChevronLeft,
    faBacteria,
    faHandLizard,
    faNotdef,
    faDisease,
    faBriefcaseMedical,
    faGenderless,
    faChevronRight,
    faRetweet,
    faCarRear,
    faCarAlt,
    faPumpSoap,
    faVideoSlash,
    faBatteryQuarter,
    faBattery2,
    faRadio,
    faBabyCarriage,
    faCarriageBaby,
    faTrafficLight,
    faThermometer,
    faVrCardboard,
    faHandMiddleFinger,
    faPercent,
    faPercentage,
    faTruckMoving,
    faGlassWaterDroplet,
    faDisplay,
    faFaceSmile,
    faSmile,
    faThumbtack,
    faThumbTack,
    faTrophy,
    faPersonPraying,
    faPray,
    faHammer,
    faHandPeace,
    faRotate,
    faSyncAlt,
    faSpinner,
    faRobot,
    faPeace,
    faGears,
    faCogs,
    faWarehouse,
    faArrowUpRightDots,
    faSplotch,
    faFaceGrinHearts,
    faGrinHearts,
    faDiceFour,
    faSimCard,
    faTransgender,
    faTransgenderAlt,
    faMercury,
    faArrowTurnDown,
    faLevelDown,
    faPersonFallingBurst,
    faAward,
    faTicketSimple,
    faTicketAlt,
    faBuilding,
    faAnglesLeft,
    faAngleDoubleLeft,
    faQrcode,
    faClockRotateLeft,
    faHistory,
    faFaceGrinBeamSweat,
    faGrinBeamSweat,
    faFileExport,
    faArrowRightFromFile,
    faShield,
    faShieldBlank,
    faArrowUpShortWide,
    faSortAmountUpAlt,
    faHouseMedical,
    faGolfBallTee,
    faGolfBall,
    faCircleChevronLeft,
    faChevronCircleLeft,
    faHouseChimneyWindow,
    faPenNib,
    faTentArrowTurnLeft,
    faTents,
    faWandMagic,
    faMagic,
    faDog,
    faCarrot,
    faMoon,
    faWineGlassEmpty,
    faWineGlassAlt,
    faCheese,
    faYinYang,
    faMusic,
    faCodeCommit,
    faTemperatureLow,
    faPersonBiking,
    faBiking,
    faBroom,
    faShieldHeart,
    faGopuram,
    faEarthOceania,
    faGlobeOceania,
    faSquareXmark,
    faTimesSquare,
    faXmarkSquare,
    faHashtag,
    faUpRightAndDownLeftFromCenter,
    faExpandAlt,
    faOilCan,
    faT,
    faHippo,
    faChartColumn,
    faInfinity,
    faVialCircleCheck,
    faPersonArrowDownToLine,
    faVoicemail,
    faFan,
    faPersonWalkingLuggage,
    faUpDown,
    faArrowsAltV,
    faCloudMoonRain,
    faCalendar,
    faTrailer,
    faBahai,
    faHaykal,
    faSdCard,
    faDragon,
    faShoePrints,
    faCirclePlus,
    faPlusCircle,
    faFaceGrinTongueWink,
    faGrinTongueWink,
    faHandHolding,
    faPlugCircleExclamation,
    faLinkSlash,
    faChainBroken,
    faChainSlash,
    faUnlink,
    faClone,
    faPersonWalkingArrowLoopLeft,
    faArrowUpZA,
    faSortAlphaUpAlt,
    faFireFlameCurved,
    faFireAlt,
    faTornado,
    faFileCirclePlus,
    faBookQuran,
    faQuran,
    faAnchor,
    faBorderAll,
    faFaceAngry,
    faAngry,
    faCookieBite,
    faArrowTrendDown,
    faRss,
    faFeed,
    faDrawPolygon,
    faScaleBalanced,
    faBalanceScale,
    faGaugeSimpleHigh,
    faTachometer,
    faTachometerFast,
    faShower,
    faDesktop,
    faDesktopAlt,
    faM,
    faTableList,
    faThList,
    faCommentSms,
    faSms,
    faBook,
    faUserPlus,
    faCheck,
    faBatteryThreeQuarters,
    faBattery4,
    faHouseCircleCheck,
    faAngleLeft,
    faDiagramSuccessor,
    faTruckArrowRight,
    faArrowsSplitUpAndLeft,
    faHandFist,
    faFistRaised,
    faCloudMoon,
    faBriefcase,
    faPersonFalling,
    faImagePortrait,
    faPortrait,
    faUserTag,
    faRug,
    faEarthEurope,
    faGlobeEurope,
    faCartFlatbedSuitcase,
    faLuggageCart,
    faRectangleXmark,
    faRectangleTimes,
    faTimesRectangle,
    faWindowClose,
    faBahtSign,
    faBookOpen,
    faBookJournalWhills,
    faJournalWhills,
    faHandcuffs,
    faTriangleExclamation,
    faExclamationTriangle,
    faWarning,
    faDatabase,
    faShare,
    faArrowTurnRight,
    faMailForward,
    faBottleDroplet,
    faMaskFace,
    faHillRockslide,
    faRightLeft,
    faExchangeAlt,
    faPaperPlane,
    faRoadCircleExclamation,
    faDungeon,
    faAlignRight,
    faMoneyBill1Wave,
    faMoneyBillWaveAlt,
    faLifeRing,
    faHands,
    faSignLanguage,
    faSigning,
    faCalendarDay,
    faWaterLadder,
    faLadderWater,
    faSwimmingPool,
    faArrowsUpDown,
    faArrowsV,
    faFaceGrimace,
    faGrimace,
    faWheelchairMove,
    faWheelchairAlt,
    faTurnDown,
    faLevelDownAlt,
    faPersonWalkingArrowRight,
    faSquareEnvelope,
    faEnvelopeSquare,
    faDice,
    faBowlingBall,
    faBrain,
    faBandage,
    faBandAid,
    faCalendarMinus,
    faCircleXmark,
    faTimesCircle,
    faXmarkCircle,
    faGifts,
    faHotel,
    faEarthAsia,
    faGlobeAsia,
    faIdCardClip,
    faIdCardAlt,
    faMagnifyingGlassPlus,
    faSearchPlus,
    faThumbsUp,
    faUserClock,
    faHandDots,
    faAllergies,
    faFileInvoice,
    faWindowMinimize,
    faMugSaucer,
    faCoffee,
    faBrush,
    faMask,
    faMagnifyingGlassMinus,
    faSearchMinus,
    faRulerVertical,
    faUserLarge,
    faUserAlt,
    faTrainTram,
    faUserNurse,
    faSyringe,
    faCloudSun,
    faStopwatch20,
    faSquareFull,
    faMagnet,
    faJar,
    faNoteSticky,
    faStickyNote,
    faBugSlash,
    faArrowUpFromWaterPump,
    faBone,
    faUserInjured,
    faFaceSadTear,
    faSadTear,
    faPlane,
    faTentArrowsDown,
    faExclamation,
    faArrowsSpin,
    faPrint,
    faTurkishLiraSign,
    faTry,
    faTurkishLira,
    faDollarSign,
    faDollar,
    faUsd,
    faX,
    faMagnifyingGlassDollar,
    faSearchDollar,
    faUsersGear,
    faUsersCog,
    faPersonMilitaryPointing,
    faBuildingColumns,
    faBank,
    faInstitution,
    faMuseum,
    faUniversity,
    faUmbrella,
    faTrowel,
    faD,
    faStapler,
    faMasksTheater,
    faTheaterMasks,
    faKipSign,
    faHandPointLeft,
    faHandshakeSimple,
    faHandshakeAlt,
    faJetFighter,
    faFighterJet,
    faSquareShareNodes,
    faShareAltSquare,
    faBarcode,
    faPlusMinus,
    faVideo,
    faVideoCamera,
    faGraduationCap,
    faMortarBoard,
    faHandHoldingMedical,
    faPersonCircleCheck,
    faTurnUp,
    faLevelUpAlt,
} from '@fortawesome/free-solid-svg-icons';
import App from './App.vue';
import router from './router';
import store from './store/store';
// import store from './store/index';
// import './constants/fontawesome'; // Импортируйте настройки иконок
// library.add(faUserSecret);
library.add(fa0);
library.add(fa1);
library.add(fa2);
library.add(fa3);
library.add(fa4);
library.add(fa5);
library.add(fa6);
library.add(fa7);
library.add(fa8);
library.add(fa9);
library.add(faFillDrip);
library.add(faArrowsToCircle);
library.add(faCircleChevronRight);
library.add(faChevronCircleRight);
library.add(faAt);
library.add(faTrashCan);
library.add(faTrashAlt);
library.add(faTextHeight);
library.add(faUserXmark);
library.add(faUserTimes);
library.add(faStethoscope);
library.add(faMessage);
library.add(faCommentAlt);
library.add(faInfo);
library.add(faDownLeftAndUpRightToCenter);
library.add(faCompressAlt);
library.add(faExplosion);
library.add(faFileLines);
library.add(faFileAlt);
library.add(faFileText);
library.add(faWaveSquare);
library.add(faRing);
library.add(faBuildingUn);
library.add(faDiceThree);
library.add(faCalendarDays);
library.add(faCalendarAlt);
library.add(faAnchorCircleCheck);
library.add(faBuildingCircleArrowRight);
library.add(faVolleyball);
library.add(faVolleyballBall);
library.add(faArrowsUpToLine);
library.add(faSortDown);
library.add(faSortDesc);
library.add(faCircleMinus);
library.add(faMinusCircle);
library.add(faDoorOpen);
library.add(faRightFromBracket);
library.add(faSignOutAlt);
library.add(faAtom);
library.add(faSoap);
library.add(faIcons);
library.add(faHeartMusicCameraBolt);
library.add(faMicrophoneLinesSlash);
library.add(faMicrophoneAltSlash);
library.add(faBridgeCircleCheck);
library.add(faPumpMedical);
library.add(faFingerprint);
library.add(faHandPointRight);
library.add(faMagnifyingGlassLocation);
library.add(faSearchLocation);
library.add(faForwardStep);
library.add(faStepForward);
library.add(faFaceSmileBeam);
library.add(faSmileBeam);
library.add(faFlagCheckered);
library.add(faFootball);
library.add(faFootballBall);
library.add(faSchoolCircleExclamation);
library.add(faCrop);
library.add(faAnglesDown);
library.add(faAngleDoubleDown);
library.add(faUsersRectangle);
library.add(faPeopleRoof);
library.add(faPeopleLine);
library.add(faBeerMugEmpty);
library.add(faBeer);
library.add(faDiagramPredecessor);
library.add(faArrowUpLong);
library.add(faLongArrowUp);
library.add(faFireFlameSimple);
library.add(faBurn);
library.add(faPerson);
library.add(faMale);
library.add(faLaptop);
library.add(faFileCsv);
library.add(faMenorah);
library.add(faTruckPlane);
library.add(faRecordVinyl);
library.add(faFaceGrinStars);
library.add(faGrinStars);
library.add(faBong);
library.add(faSpaghettiMonsterFlying);
library.add(faPastafarianism);
library.add(faArrowDownUpAcrossLine);
library.add(faSpoon);
library.add(faUtensilSpoon);
library.add(faJarWheat);
library.add(faEnvelopesBulk);
library.add(faMailBulk);
library.add(faFileCircleExclamation);
library.add(faCircleH);
library.add(faHospitalSymbol);
library.add(faPager);
library.add(faAddressBook);
library.add(faContactBook);
library.add(faStrikethrough);
library.add(faK);
library.add(faLandmarkFlag);
library.add(faPencil);
library.add(faPencilAlt);
library.add(faBackward);
library.add(faCaretRight);
library.add(faComments);
library.add(faPaste);
library.add(faFileClipboard);
library.add(faCodePullRequest);
library.add(faClipboardList);
library.add(faTruckRampBox);
library.add(faTruckLoading);
library.add(faUserCheck);
library.add(faVialVirus);
library.add(faSheetPlastic);
library.add(faBlog);
library.add(faUserNinja);
library.add(faPersonArrowUpFromLine);
library.add(faScrollTorah);
library.add(faTorah);
library.add(faBroomBall);
library.add(faQuidditch);
library.add(faQuidditchBroomBall);
library.add(faToggleOff);
library.add(faBoxArchive);
library.add(faArchive);
library.add(faPersonDrowning);
library.add(faArrowDown91);
library.add(faSortNumericDesc);
library.add(faSortNumericDownAlt);
library.add(faFaceGrinTongueSquint);
library.add(faGrinTongueSquint);
library.add(faSprayCan);
library.add(faTruckMonster);
library.add(faW);
library.add(faEarthAfrica);
library.add(faGlobeAfrica);
library.add(faRainbow);
library.add(faCircleNotch);
library.add(faTabletScreenButton);
library.add(faTabletAlt);
library.add(faPaw);
library.add(faCloud);
library.add(faTrowelBricks);
library.add(faFaceFlushed);
library.add(faFlushed);
library.add(faHospitalUser);
library.add(faTentArrowLeftRight);
library.add(faGavel);
library.add(faLegal);
library.add(faBinoculars);
library.add(faMicrophoneSlash);
library.add(faBoxTissue);
library.add(faMotorcycle);
library.add(faBellConcierge);
library.add(faConciergeBell);
library.add(faPenRuler);
library.add(faPencilRuler);
library.add(faPeopleArrows);
library.add(faPeopleArrowsLeftRight);
library.add(faMarsAndVenusBurst);
library.add(faSquareCaretRight);
library.add(faCaretSquareRight);
library.add(faScissors);
library.add(faCut);
library.add(faSunPlantWilt);
library.add(faToiletsPortable);
library.add(faHockeyPuck);
library.add(faTable);
library.add(faMagnifyingGlassArrowRight);
library.add(faTachographDigital);
library.add(faDigitalTachograph);
library.add(faUsersSlash);
library.add(faClover);
library.add(faReply);
library.add(faMailReply);
library.add(faStarAndCrescent);
library.add(faHouseFire);
library.add(faSquareMinus);
library.add(faMinusSquare);
library.add(faHelicopter);
library.add(faCompass);
library.add(faSquareCaretDown);
library.add(faCaretSquareDown);
library.add(faFileCircleQuestion);
library.add(faLaptopCode);
library.add(faSwatchbook);
library.add(faPrescriptionBottle);
library.add(faBars);
library.add(faNavicon);
library.add(faPeopleGroup);
library.add(faHourglassEnd);
library.add(faHourglass3);
library.add(faHeartCrack);
library.add(faHeartBroken);
library.add(faSquareUpRight);
library.add(faExternalLinkSquareAlt);
library.add(faFaceKissBeam);
library.add(faKissBeam);
library.add(faFilm);
library.add(faRulerHorizontal);
library.add(faPeopleRobbery);
library.add(faLightbulb);
library.add(faCaretLeft);
library.add(faCircleExclamation);
library.add(faExclamationCircle);
library.add(faSchoolCircleXmark);
library.add(faArrowRightFromBracket);
library.add(faSignOut);
library.add(faCircleChevronDown);
library.add(faChevronCircleDown);
library.add(faUnlockKeyhole);
library.add(faUnlockAlt);
library.add(faCloudShowersHeavy);
library.add(faHeadphonesSimple);
library.add(faHeadphonesAlt);
library.add(faSitemap);
library.add(faCircleDollarToSlot);
library.add(faDonate);
library.add(faMemory);
library.add(faRoadSpikes);
library.add(faFireBurner);
library.add(faFlag);
library.add(faHanukiah);
library.add(faFeather);
library.add(faVolumeLow);
library.add(faVolumeDown);
library.add(faCommentSlash);
library.add(faCloudSunRain);
library.add(faCompress);
library.add(faWheatAwn);
library.add(faWheatAlt);
library.add(faAnkh);
library.add(faHandsHoldingChild);
library.add(faAsterisk);
library.add(faSquareCheck);
library.add(faCheckSquare);
library.add(faPesetaSign);
library.add(faHeading);
library.add(faHeader);
library.add(faGhost);
library.add(faList);
library.add(faListSquares);
library.add(faSquarePhoneFlip);
library.add(faPhoneSquareAlt);
library.add(faCartPlus);
library.add(faGamepad);
library.add(faCircleDot);
library.add(faDotCircle);
library.add(faFaceDizzy);
library.add(faDizzy);
library.add(faEgg);
library.add(faHouseMedicalCircleXmark);
library.add(faCampground);
library.add(faFolderPlus);
library.add(faFutbol);
library.add(faFutbolBall);
library.add(faSoccerBall);
library.add(faPaintbrush);
library.add(faPaintBrush);
library.add(faLock);
library.add(faGasPump);
library.add(faHotTubPerson);
library.add(faHotTub);
library.add(faMapLocation);
library.add(faMapMarked);
library.add(faHouseFloodWater);
library.add(faTree);
library.add(faBridgeLock);
library.add(faSackDollar);
library.add(faPenToSquare);
library.add(faEdit);
library.add(faCarSide);
library.add(faShareNodes);
library.add(faShareAlt);
library.add(faHeartCircleMinus);
library.add(faHourglassHalf);
library.add(faHourglass2);
library.add(faMicroscope);
library.add(faSink);
library.add(faBagShopping);
library.add(faShoppingBag);
library.add(faArrowDownZA);
library.add(faSortAlphaDesc);
library.add(faSortAlphaDownAlt);
library.add(faMitten);
library.add(faPersonRays);
library.add(faUsers);
library.add(faEyeSlash);
library.add(faFlaskVial);
library.add(faHand);
library.add(faHandPaper);
library.add(faOm);
library.add(faWorm);
library.add(faHouseCircleXmark);
library.add(faPlug);
library.add(faChevronUp);
library.add(faHandSpock);
library.add(faStopwatch);
library.add(faFaceKiss);
library.add(faKiss);
library.add(faBridgeCircleXmark);
library.add(faFaceGrinTongue);
library.add(faGrinTongue);
library.add(faChessBishop);
library.add(faFaceGrinWink);
library.add(faGrinWink);
library.add(faEarDeaf);
library.add(faDeaf);
library.add(faDeafness);
library.add(faHardOfHearing);
library.add(faRoadCircleCheck);
library.add(faDiceFive);
library.add(faSquareRss);
library.add(faRssSquare);
library.add(faLandMineOn);
library.add(faICursor);
library.add(faStamp);
library.add(faStairs);
library.add(faI);
library.add(faHryvniaSign);
library.add(faHryvnia);
library.add(faPills);
library.add(faFaceGrinWide);
library.add(faGrinAlt);
library.add(faTooth);
library.add(faV);
library.add(faBangladeshiTakaSign);
library.add(faBicycle);
library.add(faStaffSnake);
library.add(faRodAsclepius);
library.add(faRodSnake);
library.add(faStaffAesculapius);
library.add(faHeadSideCoughSlash);
library.add(faTruckMedical);
library.add(faAmbulance);
library.add(faWheatAwnCircleExclamation);
library.add(faSnowman);
library.add(faMortarPestle);
library.add(faRoadBarrier);
library.add(faSchool);
library.add(faIgloo);
library.add(faJoint);
library.add(faAngleRight);
library.add(faHorse);
library.add(faQ);
library.add(faG);
library.add(faNotesMedical);
library.add(faTemperatureHalf);
library.add(faTemperature2);
library.add(faThermometer2);
library.add(faThermometerHalf);
library.add(faDongSign);
library.add(faCapsules);
library.add(faPooStorm);
library.add(faPooBolt);
library.add(faFaceFrownOpen);
library.add(faFrownOpen);
library.add(faHandPointUp);
library.add(faMoneyBill);
library.add(faBookmark);
library.add(faAlignJustify);
library.add(faUmbrellaBeach);
library.add(faHelmetUn);
library.add(faBullseye);
library.add(faBacon);
library.add(faHandPointDown);
library.add(faArrowUpFromBracket);
library.add(faFolder);
library.add(faFolderBlank);
library.add(faFileWaveform);
library.add(faFileMedicalAlt);
library.add(faRadiation);
library.add(faChartSimple);
library.add(faMarsStroke);
library.add(faVial);
library.add(faGauge);
library.add(faDashboard);
library.add(faGaugeMed);
library.add(faTachometerAltAverage);
library.add(faWandMagicSparkles);
library.add(faMagicWandSparkles);
library.add(faE);
library.add(faPenClip);
library.add(faPenAlt);
library.add(faBridgeCircleExclamation);
library.add(faUser);
library.add(faSchoolCircleCheck);
library.add(faDumpster);
library.add(faVanShuttle);
library.add(faShuttleVan);
library.add(faBuildingUser);
library.add(faSquareCaretLeft);
library.add(faCaretSquareLeft);
library.add(faHighlighter);
library.add(faKey);
library.add(faBullhorn);
library.add(faGlobe);
library.add(faSynagogue);
library.add(faPersonHalfDress);
library.add(faRoadBridge);
library.add(faLocationArrow);
library.add(faC);
library.add(faTabletButton);
library.add(faBuildingLock);
library.add(faPizzaSlice);
library.add(faMoneyBillWave);
library.add(faChartArea);
library.add(faAreaChart);
library.add(faHouseFlag);
library.add(faPersonCircleMinus);
library.add(faBan);
library.add(faCancel);
library.add(faCameraRotate);
library.add(faSprayCanSparkles);
library.add(faAirFreshener);
library.add(faStar);
library.add(faRepeat);
library.add(faCross);
library.add(faBox);
library.add(faVenusMars);
library.add(faArrowPointer);
library.add(faMousePointer);
library.add(faMaximize);
library.add(faExpandArrowsAlt);
library.add(faChargingStation);
library.add(faShapes);
library.add(faTriangleCircleSquare);
library.add(faShuffle);
library.add(faRandom);
library.add(faPersonRunning);
library.add(faRunning);
library.add(faMobileRetro);
library.add(faGripLinesVertical);
library.add(faSpider);
library.add(faHandsBound);
library.add(faFileInvoiceDollar);
library.add(faPlaneCircleExclamation);
library.add(faXRay);
library.add(faSpellCheck);
library.add(faSlash);
library.add(faComputerMouse);
library.add(faMouse);
library.add(faArrowRightToBracket);
library.add(faSignIn);
library.add(faShopSlash);
library.add(faStoreAltSlash);
library.add(faServer);
library.add(faVirusCovidSlash);
library.add(faShopLock);
library.add(faHourglassStart);
library.add(faHourglass1);
library.add(faBlenderPhone);
library.add(faBuildingWheat);
library.add(faPersonBreastfeeding);
library.add(faRightToBracket);
library.add(faSignInAlt);
library.add(faVenus);
library.add(faPassport);
library.add(faHeartPulse);
library.add(faHeartbeat);
library.add(faPeopleCarryBox);
library.add(faPeopleCarry);
library.add(faTemperatureHigh);
library.add(faMicrochip);
library.add(faCrown);
library.add(faWeightHanging);
library.add(faXmarksLines);
library.add(faFilePrescription);
library.add(faWeightScale);
library.add(faWeight);
library.add(faUserGroup);
library.add(faUserFriends);
library.add(faArrowUpAZ);
library.add(faSortAlphaUp);
library.add(faChessKnight);
library.add(faFaceLaughSquint);
library.add(faLaughSquint);
library.add(faWheelchair);
library.add(faCircleArrowUp);
library.add(faArrowCircleUp);
library.add(faToggleOn);
library.add(faPersonWalking);
library.add(faWalking);
library.add(faL);
library.add(faFire);
library.add(faBedPulse);
library.add(faProcedures);
library.add(faShuttleSpace);
library.add(faSpaceShuttle);
library.add(faFaceLaugh);
library.add(faLaugh);
library.add(faFolderOpen);
library.add(faHeartCirclePlus);
library.add(faCodeFork);
library.add(faCity);
library.add(faMicrophoneLines);
library.add(faMicrophoneAlt);
library.add(faPepperHot);
library.add(faUnlock);
library.add(faColonSign);
library.add(faHeadset);
library.add(faStoreSlash);
library.add(faRoadCircleXmark);
library.add(faUserMinus);
library.add(faMarsStrokeUp);
library.add(faMarsStrokeV);
library.add(faChampagneGlasses);
library.add(faGlassCheers);
library.add(faClipboard);
library.add(faHouseCircleExclamation);
library.add(faFileArrowUp);
library.add(faFileUpload);
library.add(faWifi);
library.add(faWifi3);
library.add(faWifiStrong);
library.add(faBath);
library.add(faBathtub);
library.add(faUnderline);
library.add(faUserPen);
library.add(faUserEdit);
library.add(faSignature);
library.add(faStroopwafel);
library.add(faBold);
library.add(faAnchorLock);
library.add(faBuildingNgo);
library.add(faManatSign);
library.add(faNotEqual);
library.add(faBorderTopLeft);
library.add(faBorderStyle);
library.add(faMapLocationDot);
library.add(faMapMarkedAlt);
library.add(faJedi);
library.add(faSquarePollVertical);
library.add(faPoll);
library.add(faMugHot);
library.add(faCarBattery);
library.add(faBatteryCar);
library.add(faGift);
library.add(faDiceTwo);
library.add(faChessQueen);
library.add(faGlasses);
library.add(faChessBoard);
library.add(faBuildingCircleCheck);
library.add(faPersonChalkboard);
library.add(faMarsStrokeRight);
library.add(faMarsStrokeH);
library.add(faHandBackFist);
library.add(faHandRock);
library.add(faSquareCaretUp);
library.add(faCaretSquareUp);
library.add(faCloudShowersWater);
library.add(faChartBar);
library.add(faBarChart);
library.add(faHandsBubbles);
library.add(faHandsWash);
library.add(faLessThanEqual);
library.add(faTrain);
library.add(faEyeLowVision);
library.add(faLowVision);
library.add(faCrow);
library.add(faSailboat);
library.add(faWindowRestore);
library.add(faSquarePlus);
library.add(faPlusSquare);
library.add(faToriiGate);
library.add(faFrog);
library.add(faBucket);
library.add(faImage);
library.add(faMicrophone);
library.add(faCow);
library.add(faCaretUp);
library.add(faScrewdriver);
library.add(faFolderClosed);
library.add(faHouseTsunami);
library.add(faSquareNfi);
library.add(faArrowUpFromGroundWater);
library.add(faMartiniGlass);
library.add(faGlassMartiniAlt);
library.add(faRotateLeft);
library.add(faRotateBack);
library.add(faRotateBackward);
library.add(faUndoAlt);
library.add(faTableColumns);
library.add(faColumns);
library.add(faLemon);
library.add(faHeadSideMask);
library.add(faHandshake);
library.add(faGem);
library.add(faDolly);
library.add(faDollyBox);
library.add(faSmoking);
library.add(faMinimize);
library.add(faCompressArrowsAlt);
library.add(faMonument);
library.add(faSnowplow);
library.add(faAnglesRight);
library.add(faAngleDoubleRight);
library.add(faCannabis);
library.add(faCirclePlay);
library.add(faPlayCircle);
library.add(faTablets);
library.add(faEthernet);
library.add(faEuroSign);
library.add(faEur);
library.add(faEuro);
library.add(faChair);
library.add(faCircleCheck);
library.add(faCheckCircle);
library.add(faCircleStop);
library.add(faStopCircle);
library.add(faCompassDrafting);
library.add(faDraftingCompass);
library.add(faPlateWheat);
library.add(faIcicles);
library.add(faPersonShelter);
library.add(faNeuter);
library.add(faIdBadge);
library.add(faMarker);
library.add(faFaceLaughBeam);
library.add(faLaughBeam);
library.add(faHelicopterSymbol);
library.add(faUniversalAccess);
library.add(faCircleChevronUp);
library.add(faChevronCircleUp);
library.add(faLariSign);
library.add(faVolcano);
library.add(faPersonWalkingDashedLineArrowRight);
library.add(faSterlingSign);
library.add(faGbp);
library.add(faPoundSign);
library.add(faViruses);
library.add(faSquarePersonConfined);
library.add(faUserTie);
library.add(faArrowDownLong);
library.add(faLongArrowDown);
library.add(faTentArrowDownToLine);
library.add(faCertificate);
library.add(faReplyAll);
library.add(faMailReplyAll);
library.add(faSuitcase);
library.add(faPersonSkating);
library.add(faSkating);
library.add(faFilterCircleDollar);
library.add(faFunnelDollar);
library.add(faCameraRetro);
library.add(faCircleArrowDown);
library.add(faArrowCircleDown);
library.add(faFileImport);
library.add(faArrowRightToFile);
library.add(faSquareArrowUpRight);
library.add(faExternalLinkSquare);
library.add(faBoxOpen);
library.add(faScroll);
library.add(faSpa);
library.add(faLocationPinLock);
library.add(faPause);
library.add(faHillAvalanche);
library.add(faTemperatureEmpty);
library.add(faTemperature0);
library.add(faThermometer0);
library.add(faThermometerEmpty);
library.add(faBomb);
library.add(faRegistered);
library.add(faAddressCard);
library.add(faContactCard);
library.add(faVcard);
library.add(faScaleUnbalancedFlip);
library.add(faBalanceScaleRight);
library.add(faSubscript);
library.add(faDiamondTurnRight);
library.add(faDirections);
library.add(faBurst);
library.add(faHouseLaptop);
library.add(faLaptopHouse);
library.add(faFaceTired);
library.add(faTired);
library.add(faMoneyBills);
library.add(faSmog);
library.add(faCrutch);
library.add(faFontAwesome);
library.add(faFontAwesomeFlag);
library.add(faFontAwesomeLogoFull);
library.add(faCloudArrowUp);
library.add(faCloudUpload);
library.add(faCloudUploadAlt);
library.add(faPalette);
library.add(faArrowsTurnRight);
library.add(faVest);
library.add(faFerry);
library.add(faArrowsDownToPeople);
library.add(faSeedling);
library.add(faSprout);
library.add(faLeftRight);
library.add(faArrowsAltH);
library.add(faBoxesPacking);
library.add(faCircleArrowLeft);
library.add(faArrowCircleLeft);
library.add(faGroupArrowsRotate);
library.add(faBowlFood);
library.add(faCandyCane);
library.add(faArrowDownWideShort);
library.add(faSortAmountAsc);
library.add(faSortAmountDown);
library.add(faCloudBolt);
library.add(faThunderstorm);
library.add(faTextSlash);
library.add(faRemoveFormat);
library.add(faFaceSmileWink);
library.add(faSmileWink);
library.add(faFileWord);
library.add(faFilePowerpoint);
library.add(faArrowsLeftRight);
library.add(faArrowsH);
library.add(faHouseLock);
library.add(faCloudArrowDown);
library.add(faCloudDownload);
library.add(faCloudDownloadAlt);
library.add(faChildren);
library.add(faChalkboard);
library.add(faBlackboard);
library.add(faUserLargeSlash);
library.add(faUserAltSlash);
library.add(faEnvelopeOpen);
library.add(faHandshakeSimpleSlash);
library.add(faHandshakeAltSlash);
library.add(faMattressPillow);
library.add(faGuaraniSign);
library.add(faArrowsRotate);
library.add(faRefresh);
library.add(faSync);
library.add(faFireExtinguisher);
library.add(faCruzeiroSign);
library.add(faGreaterThanEqual);
library.add(faShieldHalved);
library.add(faShieldAlt);
library.add(faBookAtlas);
library.add(faAtlas);
library.add(faVirus);
library.add(faEnvelopeCircleCheck);
library.add(faLayerGroup);
library.add(faArrowsToDot);
library.add(faArchway);
library.add(faHeartCircleCheck);
library.add(faHouseChimneyCrack);
library.add(faHouseDamage);
library.add(faFileZipper);
library.add(faFileArchive);
library.add(faSquare);
library.add(faMartiniGlassEmpty);
library.add(faGlassMartini);
library.add(faCouch);
library.add(faCediSign);
library.add(faItalic);
library.add(faChurch);
library.add(faCommentsDollar);
library.add(faDemocrat);
library.add(faZ);
library.add(faPersonSkiing);
library.add(faSkiing);
library.add(faRoadLock);
library.add(faA);
library.add(faTemperatureArrowDown);
library.add(faTemperatureDown);
library.add(faFeatherPointed);
library.add(faFeatherAlt);
library.add(faP);
library.add(faSnowflake);
library.add(faNewspaper);
library.add(faRectangleAd);
library.add(faAd);
library.add(faCircleArrowRight);
library.add(faArrowCircleRight);
library.add(faFilterCircleXmark);
library.add(faLocust);
library.add(faSort);
library.add(faUnsorted);
library.add(faListOl);
library.add(faList12);
library.add(faListNumeric);
library.add(faPersonDressBurst);
library.add(faMoneyCheckDollar);
library.add(faMoneyCheckAlt);
library.add(faVectorSquare);
library.add(faBreadSlice);
library.add(faLanguage);
library.add(faFaceKissWinkHeart);
library.add(faKissWinkHeart);
library.add(faFilter);
library.add(faQuestion);
library.add(faFileSignature);
library.add(faUpDownLeftRight);
library.add(faArrowsAlt);
library.add(faHouseChimneyUser);
library.add(faHandHoldingHeart);
library.add(faPuzzlePiece);
library.add(faMoneyCheck);
library.add(faStarHalfStroke);
library.add(faStarHalfAlt);
library.add(faCode);
library.add(faWhiskeyGlass);
library.add(faGlassWhiskey);
library.add(faBuildingCircleExclamation);
library.add(faMagnifyingGlassChart);
library.add(faArrowUpRightFromSquare);
library.add(faExternalLink);
library.add(faCubesStacked);
library.add(faWonSign);
library.add(faKrw);
library.add(faWon);
library.add(faVirusCovid);
library.add(faAustralSign);
library.add(faF);
library.add(faLeaf);
library.add(faRoad);
library.add(faTaxi);
library.add(faCab);
library.add(faPersonCirclePlus);
library.add(faChartPie);
library.add(faPieChart);
library.add(faBoltLightning);
library.add(faSackXmark);
library.add(faFileExcel);
library.add(faFileContract);
library.add(faFishFins);
library.add(faBuildingFlag);
library.add(faFaceGrinBeam);
library.add(faGrinBeam);
library.add(faObjectUngroup);
library.add(faPoop);
library.add(faLocationPin);
library.add(faMapMarker);
library.add(faKaaba);
library.add(faToiletPaper);
library.add(faHelmetSafety);
library.add(faHardHat);
library.add(faHatHard);
library.add(faEject);
library.add(faCircleRight);
library.add(faArrowAltCircleRight);
library.add(faPlaneCircleCheck);
library.add(faFaceRollingEyes);
library.add(faMehRollingEyes);
library.add(faObjectGroup);
library.add(faChartLine);
library.add(faLineChart);
library.add(faMaskVentilator);
library.add(faArrowRight);
library.add(faSignsPost);
library.add(faMapSigns);
library.add(faCashRegister);
library.add(faPersonCircleQuestion);
library.add(faH);
library.add(faTarp);
library.add(faScrewdriverWrench);
library.add(faTools);
library.add(faArrowsToEye);
library.add(faPlugCircleBolt);
library.add(faHeart);
library.add(faMarsAndVenus);
library.add(faHouseUser);
library.add(faHomeUser);
library.add(faDumpsterFire);
library.add(faHouseCrack);
library.add(faMartiniGlassCitrus);
library.add(faCocktail);
library.add(faFaceSurprise);
library.add(faSurprise);
library.add(faBottleWater);
library.add(faCirclePause);
library.add(faPauseCircle);
library.add(faToiletPaperSlash);
library.add(faAppleWhole);
library.add(faAppleAlt);
library.add(faKitchenSet);
library.add(faR);
library.add(faTemperatureQuarter);
library.add(faTemperature1);
library.add(faThermometer1);
library.add(faThermometerQuarter);
library.add(faCube);
library.add(faBitcoinSign);
library.add(faShieldDog);
library.add(faSolarPanel);
library.add(faLockOpen);
library.add(faElevator);
library.add(faMoneyBillTransfer);
library.add(faMoneyBillTrendUp);
library.add(faHouseFloodWaterCircleArrowRight);
library.add(faSquarePollHorizontal);
library.add(faPollH);
library.add(faCircle);
library.add(faBackwardFast);
library.add(faFastBackward);
library.add(faRecycle);
library.add(faUserAstronaut);
library.add(faPlaneSlash);
library.add(faTrademark);
library.add(faBasketball);
library.add(faBasketballBall);
library.add(faSatelliteDish);
library.add(faCircleUp);
library.add(faArrowAltCircleUp);
library.add(faMobileScreenButton);
library.add(faMobileAlt);
library.add(faVolumeHigh);
library.add(faVolumeUp);
library.add(faUsersRays);
library.add(faWallet);
library.add(faClipboardCheck);
library.add(faFileAudio);
library.add(faBurger);
library.add(faHamburger);
library.add(faWrench);
library.add(faBugs);
library.add(faRupeeSign);
library.add(faRupee);
library.add(faFileImage);
library.add(faCircleQuestion);
library.add(faQuestionCircle);
library.add(faPlaneDeparture);
library.add(faHandshakeSlash);
library.add(faBookBookmark);
library.add(faCodeBranch);
library.add(faHatCowboy);
library.add(faBridge);
library.add(faPhoneFlip);
library.add(faPhoneAlt);
library.add(faTruckFront);
library.add(faCat);
library.add(faAnchorCircleExclamation);
library.add(faTruckField);
library.add(faRoute);
library.add(faClipboardQuestion);
library.add(faPanorama);
library.add(faCommentMedical);
library.add(faTeethOpen);
library.add(faFileCircleMinus);
library.add(faTags);
library.add(faWineGlass);
library.add(faForwardFast);
library.add(faFastForward);
library.add(faFaceMehBlank);
library.add(faMehBlank);
library.add(faSquareParking);
library.add(faParking);
library.add(faHouseSignal);
library.add(faBarsProgress);
library.add(faTasksAlt);
library.add(faFaucetDrip);
library.add(faCartFlatbed);
library.add(faDollyFlatbed);
library.add(faBanSmoking);
library.add(faSmokingBan);
library.add(faTerminal);
library.add(faMobileButton);
library.add(faHouseMedicalFlag);
library.add(faBasketShopping);
library.add(faShoppingBasket);
library.add(faTape);
library.add(faBusSimple);
library.add(faBusAlt);
library.add(faEye);
library.add(faFaceSadCry);
library.add(faSadCry);
library.add(faAudioDescription);
library.add(faPersonMilitaryToPerson);
library.add(faFileShield);
library.add(faUserSlash);
library.add(faPen);
library.add(faTowerObservation);
library.add(faFileCode);
library.add(faSignal);
library.add(faSignal5);
library.add(faSignalPerfect);
library.add(faBus);
library.add(faHeartCircleXmark);
library.add(faHouseChimney);
library.add(faHomeLg);
library.add(faWindowMaximize);
library.add(faFaceFrown);
library.add(faFrown);
library.add(faPrescription);
library.add(faShop);
library.add(faStoreAlt);
library.add(faFloppyDisk);
library.add(faSave);
library.add(faVihara);
library.add(faScaleUnbalanced);
library.add(faBalanceScaleLeft);
library.add(faSortUp);
library.add(faSortAsc);
library.add(faCommentDots);
library.add(faCommenting);
library.add(faPlantWilt);
library.add(faDiamond);
library.add(faFaceGrinSquint);
library.add(faGrinSquint);
library.add(faHandHoldingDollar);
library.add(faHandHoldingUsd);
library.add(faBacterium);
library.add(faHandPointer);
library.add(faDrumSteelpan);
library.add(faHandScissors);
library.add(faHandsPraying);
library.add(faPrayingHands);
library.add(faArrowRotateRight);
library.add(faArrowRightRotate);
library.add(faArrowRotateForward);
library.add(faRedo);
library.add(faBiohazard);
library.add(faLocationCrosshairs);
library.add(faLocation);
library.add(faMarsDouble);
library.add(faChildDress);
library.add(faUsersBetweenLines);
library.add(faLungsVirus);
library.add(faFaceGrinTears);
library.add(faGrinTears);
library.add(faPhone);
library.add(faCalendarXmark);
library.add(faCalendarTimes);
library.add(faChildReaching);
library.add(faHeadSideVirus);
library.add(faUserGear);
library.add(faUserCog);
library.add(faArrowUp19);
library.add(faSortNumericUp);
library.add(faDoorClosed);
library.add(faShieldVirus);
library.add(faDiceSix);
library.add(faMosquitoNet);
library.add(faBridgeWater);
library.add(faPersonBooth);
library.add(faTextWidth);
library.add(faHatWizard);
library.add(faPenFancy);
library.add(faPersonDigging);
library.add(faDigging);
library.add(faTrash);
library.add(faGaugeSimple);
library.add(faGaugeSimpleMed);
library.add(faTachometerAverage);
library.add(faBookMedical);
library.add(faPoo);
library.add(faQuoteRight);
library.add(faQuoteRightAlt);
library.add(faShirt);
library.add(faTShirt);
library.add(faTshirt);
library.add(faCubes);
library.add(faDivide);
library.add(faTengeSign);
library.add(faTenge);
library.add(faHeadphones);
library.add(faHandsHolding);
library.add(faHandsClapping);
library.add(faRepublican);
library.add(faArrowLeft);
library.add(faPersonCircleXmark);
library.add(faRuler);
library.add(faAlignLeft);
library.add(faDiceD6);
library.add(faRestroom);
library.add(faJ);
library.add(faUsersViewfinder);
library.add(faFileVideo);
library.add(faUpRightFromSquare);
library.add(faExternalLinkAlt);
library.add(faTableCells);
library.add(faTh);
library.add(faFilePdf);
library.add(faBookBible);
library.add(faBible);
library.add(faO);
library.add(faSuitcaseMedical);
library.add(faMedkit);
library.add(faUserSecret);
library.add(faOtter);
library.add(faPersonDress);
library.add(faFemale);
library.add(faCommentDollar);
library.add(faBusinessTime);
library.add(faBriefcaseClock);
library.add(faTableCellsLarge);
library.add(faThLarge);
library.add(faBookTanakh);
library.add(faTanakh);
library.add(faPhoneVolume);
library.add(faVolumeControlPhone);
library.add(faHatCowboySide);
library.add(faClipboardUser);
library.add(faChild);
library.add(faLiraSign);
library.add(faSatellite);
library.add(faPlaneLock);
library.add(faTag);
library.add(faComment);
library.add(faCakeCandles);
library.add(faBirthdayCake);
library.add(faCake);
library.add(faEnvelope);
library.add(faAnglesUp);
library.add(faAngleDoubleUp);
library.add(faPaperclip);
library.add(faArrowRightToCity);
library.add(faRibbon);
library.add(faLungs);
library.add(faArrowUp91);
library.add(faSortNumericUpAlt);
library.add(faLitecoinSign);
library.add(faBorderNone);
library.add(faCircleNodes);
library.add(faParachuteBox);
library.add(faIndent);
library.add(faTruckFieldUn);
library.add(faHourglass);
library.add(faHourglassEmpty);
library.add(faMountain);
library.add(faUserDoctor);
library.add(faUserMd);
library.add(faCircleInfo);
library.add(faInfoCircle);
library.add(faCloudMeatball);
library.add(faCamera);
library.add(faCameraAlt);
library.add(faSquareVirus);
library.add(faMeteor);
library.add(faCarOn);
library.add(faSleigh);
library.add(faArrowDown19);
library.add(faSortNumericAsc);
library.add(faSortNumericDown);
library.add(faHandHoldingDroplet);
library.add(faHandHoldingWater);
library.add(faWater);
library.add(faCalendarCheck);
library.add(faBraille);
library.add(faPrescriptionBottleMedical);
library.add(faPrescriptionBottleAlt);
library.add(faLandmark);
library.add(faTruck);
library.add(faCrosshairs);
library.add(faPersonCane);
library.add(faTent);
library.add(faVestPatches);
library.add(faCheckDouble);
library.add(faArrowDownAZ);
library.add(faSortAlphaAsc);
library.add(faSortAlphaDown);
library.add(faMoneyBillWheat);
library.add(faCookie);
library.add(faArrowRotateLeft);
library.add(faArrowLeftRotate);
library.add(faArrowRotateBack);
library.add(faArrowRotateBackward);
library.add(faUndo);
library.add(faHardDrive);
library.add(faHdd);
library.add(faFaceGrinSquintTears);
library.add(faGrinSquintTears);
library.add(faDumbbell);
library.add(faRectangleList);
library.add(faListAlt);
library.add(faTarpDroplet);
library.add(faHouseMedicalCircleCheck);
library.add(faPersonSkiingNordic);
library.add(faSkiingNordic);
library.add(faCalendarPlus);
library.add(faPlaneArrival);
library.add(faCircleLeft);
library.add(faArrowAltCircleLeft);
library.add(faTrainSubway);
library.add(faSubway);
library.add(faChartGantt);
library.add(faIndianRupeeSign);
library.add(faIndianRupee);
library.add(faInr);
library.add(faCropSimple);
library.add(faCropAlt);
library.add(faMoneyBill1);
library.add(faMoneyBillAlt);
library.add(faLeftLong);
library.add(faLongArrowAltLeft);
library.add(faDna);
library.add(faVirusSlash);
library.add(faMinus);
library.add(faSubtract);
library.add(faChess);
library.add(faArrowLeftLong);
library.add(faLongArrowLeft);
library.add(faPlugCircleCheck);
library.add(faStreetView);
library.add(faFrancSign);
library.add(faVolumeOff);
library.add(faHandsAslInterpreting);
library.add(faAmericanSignLanguageInterpreting);
library.add(faAslInterpreting);
library.add(faHandsAmericanSignLanguageInterpreting);
library.add(faGear);
library.add(faCog);
library.add(faDropletSlash);
library.add(faTintSlash);
library.add(faMosque);
library.add(faMosquito);
library.add(faStarOfDavid);
library.add(faPersonMilitaryRifle);
library.add(faCartShopping);
library.add(faShoppingCart);
library.add(faVials);
library.add(faPlugCirclePlus);
library.add(faPlaceOfWorship);
library.add(faGripVertical);
library.add(faArrowTurnUp);
library.add(faLevelUp);
library.add(faU);
library.add(faSquareRootVariable);
library.add(faSquareRootAlt);
library.add(faClock);
library.add(faClockFour);
library.add(faBackwardStep);
library.add(faStepBackward);
library.add(faPallet);
library.add(faFaucet);
library.add(faBaseballBatBall);
library.add(faS);
library.add(faTimeline);
library.add(faKeyboard);
library.add(faCaretDown);
library.add(faHouseChimneyMedical);
library.add(faClinicMedical);
library.add(faTemperatureThreeQuarters);
library.add(faTemperature3);
library.add(faThermometer3);
library.add(faThermometerThreeQuarters);
library.add(faMobileScreen);
library.add(faMobileAndroidAlt);
library.add(faPlaneUp);
library.add(faPiggyBank);
library.add(faBatteryHalf);
library.add(faBattery3);
library.add(faMountainCity);
library.add(faCoins);
library.add(faKhanda);
library.add(faSliders);
library.add(faSlidersH);
library.add(faFolderTree);
library.add(faNetworkWired);
library.add(faMapPin);
library.add(faHamsa);
library.add(faCentSign);
library.add(faFlask);
library.add(faPersonPregnant);
library.add(faWandSparkles);
library.add(faEllipsisVertical);
library.add(faEllipsisV);
library.add(faTicket);
library.add(faPowerOff);
library.add(faRightLong);
library.add(faLongArrowAltRight);
library.add(faFlagUsa);
library.add(faLaptopFile);
library.add(faTty);
library.add(faTeletype);
library.add(faDiagramNext);
library.add(faPersonRifle);
library.add(faHouseMedicalCircleExclamation);
library.add(faClosedCaptioning);
library.add(faPersonHiking);
library.add(faHiking);
library.add(faVenusDouble);
library.add(faImages);
library.add(faCalculator);
library.add(faPeoplePulling);
library.add(faN);
library.add(faCableCar);
library.add(faTram);
library.add(faCloudRain);
library.add(faBuildingCircleXmark);
library.add(faShip);
library.add(faArrowsDownToLine);
library.add(faDownload);
library.add(faFaceGrin);
library.add(faGrin);
library.add(faDeleteLeft);
library.add(faBackspace);
library.add(faEyeDropper);
library.add(faEyeDropperEmpty);
library.add(faEyedropper);
library.add(faFileCircleCheck);
library.add(faForward);
library.add(faMobile);
library.add(faMobileAndroid);
library.add(faMobilePhone);
library.add(faFaceMeh);
library.add(faMeh);
library.add(faAlignCenter);
library.add(faBookSkull);
library.add(faBookDead);
library.add(faIdCard);
library.add(faDriversLicense);
library.add(faOutdent);
library.add(faDedent);
library.add(faHeartCircleExclamation);
library.add(faHouse);
library.add(faHome);
library.add(faHomeAlt);
library.add(faHomeLgAlt);
library.add(faCalendarWeek);
library.add(faLaptopMedical);
library.add(faB);
library.add(faFileMedical);
library.add(faDiceOne);
library.add(faKiwiBird);
library.add(faArrowRightArrowLeft);
library.add(faExchange);
library.add(faRotateRight);
library.add(faRedoAlt);
library.add(faRotateForward);
library.add(faUtensils);
library.add(faCutlery);
library.add(faArrowUpWideShort);
library.add(faSortAmountUp);
library.add(faMillSign);
library.add(faBowlRice);
library.add(faSkull);
library.add(faTowerBroadcast);
library.add(faBroadcastTower);
library.add(faTruckPickup);
library.add(faUpLong);
library.add(faLongArrowAltUp);
library.add(faStop);
library.add(faCodeMerge);
library.add(faUpload);
library.add(faHurricane);
library.add(faMound);
library.add(faToiletPortable);
library.add(faCompactDisc);
library.add(faFileArrowDown);
library.add(faFileDownload);
library.add(faCaravan);
library.add(faShieldCat);
library.add(faBolt);
library.add(faZap);
library.add(faGlassWater);
library.add(faOilWell);
library.add(faVault);
library.add(faMars);
library.add(faToilet);
library.add(faPlaneCircleXmark);
library.add(faYenSign);
library.add(faCny);
library.add(faJpy);
library.add(faRmb);
library.add(faYen);
library.add(faRubleSign);
library.add(faRouble);
library.add(faRub);
library.add(faRuble);
library.add(faSun);
library.add(faGuitar);
library.add(faFaceLaughWink);
library.add(faLaughWink);
library.add(faHorseHead);
library.add(faBoreHole);
library.add(faIndustry);
library.add(faCircleDown);
library.add(faArrowAltCircleDown);
library.add(faArrowsTurnToDots);
library.add(faFlorinSign);
library.add(faArrowDownShortWide);
library.add(faSortAmountDesc);
library.add(faSortAmountDownAlt);
library.add(faLessThan);
library.add(faAngleDown);
library.add(faCarTunnel);
library.add(faHeadSideCough);
library.add(faGripLines);
library.add(faThumbsDown);
library.add(faUserLock);
library.add(faArrowRightLong);
library.add(faLongArrowRight);
library.add(faAnchorCircleXmark);
library.add(faEllipsis);
library.add(faEllipsisH);
library.add(faChessPawn);
library.add(faKitMedical);
library.add(faFirstAid);
library.add(faPersonThroughWindow);
library.add(faToolbox);
library.add(faHandsHoldingCircle);
library.add(faBug);
library.add(faCreditCard);
library.add(faCreditCardAlt);
library.add(faCar);
library.add(faAutomobile);
library.add(faHandHoldingHand);
library.add(faBookOpenReader);
library.add(faBookReader);
library.add(faMountainSun);
library.add(faArrowsLeftRightToLine);
library.add(faDiceD20);
library.add(faTruckDroplet);
library.add(faFileCircleXmark);
library.add(faTemperatureArrowUp);
library.add(faTemperatureUp);
library.add(faMedal);
library.add(faBed);
library.add(faSquareH);
library.add(faHSquare);
library.add(faPodcast);
library.add(faTemperatureFull);
library.add(faTemperature4);
library.add(faThermometer4);
library.add(faThermometerFull);
library.add(faBell);
library.add(faSuperscript);
library.add(faPlugCircleXmark);
library.add(faStarOfLife);
library.add(faPhoneSlash);
library.add(faPaintRoller);
library.add(faHandshakeAngle);
library.add(faHandsHelping);
library.add(faLocationDot);
library.add(faMapMarkerAlt);
library.add(faFile);
library.add(faGreaterThan);
library.add(faPersonSwimming);
library.add(faSwimmer);
library.add(faArrowDown);
library.add(faDroplet);
library.add(faTint);
library.add(faEraser);
library.add(faEarthAmericas);
library.add(faEarth);
library.add(faEarthAmerica);
library.add(faGlobeAmericas);
library.add(faPersonBurst);
library.add(faDove);
library.add(faBatteryEmpty);
library.add(faBattery0);
library.add(faSocks);
library.add(faInbox);
library.add(faSection);
library.add(faGaugeHigh);
library.add(faTachometerAlt);
library.add(faTachometerAltFast);
library.add(faEnvelopeOpenText);
library.add(faHospital);
library.add(faHospitalAlt);
library.add(faHospitalWide);
library.add(faWineBottle);
library.add(faChessRook);
library.add(faBarsStaggered);
library.add(faReorder);
library.add(faStream);
library.add(faDharmachakra);
library.add(faHotdog);
library.add(faPersonWalkingWithCane);
library.add(faBlind);
library.add(faDrum);
library.add(faIceCream);
library.add(faHeartCircleBolt);
library.add(faFax);
library.add(faParagraph);
library.add(faCheckToSlot);
library.add(faVoteYea);
library.add(faStarHalf);
library.add(faBoxesStacked);
library.add(faBoxes);
library.add(faBoxesAlt);
library.add(faLink);
library.add(faChain);
library.add(faEarListen);
library.add(faAssistiveListeningSystems);
library.add(faTreeCity);
library.add(faPlay);
library.add(faFont);
library.add(faRupiahSign);
library.add(faMagnifyingGlass);
library.add(faSearch);
library.add(faTableTennisPaddleBall);
library.add(faPingPongPaddleBall);
library.add(faTableTennis);
library.add(faPersonDotsFromLine);
library.add(faDiagnoses);
library.add(faTrashCanArrowUp);
library.add(faTrashRestoreAlt);
library.add(faNairaSign);
library.add(faCartArrowDown);
library.add(faWalkieTalkie);
library.add(faFilePen);
library.add(faFileEdit);
library.add(faReceipt);
library.add(faSquarePen);
library.add(faPenSquare);
library.add(faPencilSquare);
library.add(faSuitcaseRolling);
library.add(faPersonCircleExclamation);
library.add(faChevronDown);
library.add(faBatteryFull);
library.add(faBattery);
library.add(faBattery5);
library.add(faSkullCrossbones);
library.add(faCodeCompare);
library.add(faListUl);
library.add(faListDots);
library.add(faSchoolLock);
library.add(faTowerCell);
library.add(faDownLong);
library.add(faLongArrowAltDown);
library.add(faRankingStar);
library.add(faChessKing);
library.add(faPersonHarassing);
library.add(faBrazilianRealSign);
library.add(faLandmarkDome);
library.add(faLandmarkAlt);
library.add(faArrowUp);
library.add(faTv);
library.add(faTelevision);
library.add(faTvAlt);
library.add(faShrimp);
library.add(faListCheck);
library.add(faTasks);
library.add(faJugDetergent);
library.add(faCircleUser);
library.add(faUserCircle);
library.add(faUserShield);
library.add(faWind);
library.add(faCarBurst);
library.add(faCarCrash);
library.add(faY);
library.add(faPersonSnowboarding);
library.add(faSnowboarding);
library.add(faTruckFast);
library.add(faShippingFast);
library.add(faFish);
library.add(faUserGraduate);
library.add(faCircleHalfStroke);
library.add(faAdjust);
library.add(faClapperboard);
library.add(faCircleRadiation);
library.add(faRadiationAlt);
library.add(faBaseball);
library.add(faBaseballBall);
library.add(faJetFighterUp);
library.add(faDiagramProject);
library.add(faProjectDiagram);
library.add(faCopy);
library.add(faVolumeXmark);
library.add(faVolumeMute);
library.add(faVolumeTimes);
library.add(faHandSparkles);
library.add(faGrip);
library.add(faGripHorizontal);
library.add(faShareFromSquare);
library.add(faShareSquare);
library.add(faChildCombatant);
library.add(faChildRifle);
library.add(faGun);
library.add(faSquarePhone);
library.add(faPhoneSquare);
library.add(faPlus);
library.add(faAdd);
library.add(faExpand);
library.add(faComputer);
library.add(faXmark);
library.add(faClose);
library.add(faMultiply);
library.add(faRemove);
library.add(faTimes);
library.add(faArrowsUpDownLeftRight);
library.add(faArrows);
library.add(faChalkboardUser);
library.add(faChalkboardTeacher);
library.add(faPesoSign);
library.add(faBuildingShield);
library.add(faBaby);
library.add(faUsersLine);
library.add(faQuoteLeft);
library.add(faQuoteLeftAlt);
library.add(faTractor);
library.add(faTrashArrowUp);
library.add(faTrashRestore);
library.add(faArrowDownUpLock);
library.add(faLinesLeaning);
library.add(faRulerCombined);
library.add(faCopyright);
library.add(faEquals);
library.add(faBlender);
library.add(faTeeth);
library.add(faShekelSign);
library.add(faIls);
library.add(faShekel);
library.add(faSheqel);
library.add(faSheqelSign);
library.add(faMap);
library.add(faRocket);
library.add(faPhotoFilm);
library.add(faPhotoVideo);
library.add(faFolderMinus);
library.add(faStore);
library.add(faArrowTrendUp);
library.add(faPlugCircleMinus);
library.add(faSignHanging);
library.add(faSign);
library.add(faBezierCurve);
library.add(faBellSlash);
library.add(faTablet);
library.add(faTabletAndroid);
library.add(faSchoolFlag);
library.add(faFill);
library.add(faAngleUp);
library.add(faDrumstickBite);
library.add(faHollyBerry);
library.add(faChevronLeft);
library.add(faBacteria);
library.add(faHandLizard);
library.add(faNotdef);
library.add(faDisease);
library.add(faBriefcaseMedical);
library.add(faGenderless);
library.add(faChevronRight);
library.add(faRetweet);
library.add(faCarRear);
library.add(faCarAlt);
library.add(faPumpSoap);
library.add(faVideoSlash);
library.add(faBatteryQuarter);
library.add(faBattery2);
library.add(faRadio);
library.add(faBabyCarriage);
library.add(faCarriageBaby);
library.add(faTrafficLight);
library.add(faThermometer);
library.add(faVrCardboard);
library.add(faHandMiddleFinger);
library.add(faPercent);
library.add(faPercentage);
library.add(faTruckMoving);
library.add(faGlassWaterDroplet);
library.add(faDisplay);
library.add(faFaceSmile);
library.add(faSmile);
library.add(faThumbtack);
library.add(faThumbTack);
library.add(faTrophy);
library.add(faPersonPraying);
library.add(faPray);
library.add(faHammer);
library.add(faHandPeace);
library.add(faRotate);
library.add(faSyncAlt);
library.add(faSpinner);
library.add(faRobot);
library.add(faPeace);
library.add(faGears);
library.add(faCogs);
library.add(faWarehouse);
library.add(faArrowUpRightDots);
library.add(faSplotch);
library.add(faFaceGrinHearts);
library.add(faGrinHearts);
library.add(faDiceFour);
library.add(faSimCard);
library.add(faTransgender);
library.add(faTransgenderAlt);
library.add(faMercury);
library.add(faArrowTurnDown);
library.add(faLevelDown);
library.add(faPersonFallingBurst);
library.add(faAward);
library.add(faTicketSimple);
library.add(faTicketAlt);
library.add(faBuilding);
library.add(faAnglesLeft);
library.add(faAngleDoubleLeft);
library.add(faQrcode);
library.add(faClockRotateLeft);
library.add(faHistory);
library.add(faFaceGrinBeamSweat);
library.add(faGrinBeamSweat);
library.add(faFileExport);
library.add(faArrowRightFromFile);
library.add(faShield);
library.add(faShieldBlank);
library.add(faArrowUpShortWide);
library.add(faSortAmountUpAlt);
library.add(faHouseMedical);
library.add(faGolfBallTee);
library.add(faGolfBall);
library.add(faCircleChevronLeft);
library.add(faChevronCircleLeft);
library.add(faHouseChimneyWindow);
library.add(faPenNib);
library.add(faTentArrowTurnLeft);
library.add(faTents);
library.add(faWandMagic);
library.add(faMagic);
library.add(faDog);
library.add(faCarrot);
library.add(faMoon);
library.add(faWineGlassEmpty);
library.add(faWineGlassAlt);
library.add(faCheese);
library.add(faYinYang);
library.add(faMusic);
library.add(faCodeCommit);
library.add(faTemperatureLow);
library.add(faPersonBiking);
library.add(faBiking);
library.add(faBroom);
library.add(faShieldHeart);
library.add(faGopuram);
library.add(faEarthOceania);
library.add(faGlobeOceania);
library.add(faSquareXmark);
library.add(faTimesSquare);
library.add(faXmarkSquare);
library.add(faHashtag);
library.add(faUpRightAndDownLeftFromCenter);
library.add(faExpandAlt);
library.add(faOilCan);
library.add(faT);
library.add(faHippo);
library.add(faChartColumn);
library.add(faInfinity);
library.add(faVialCircleCheck);
library.add(faPersonArrowDownToLine);
library.add(faVoicemail);
library.add(faFan);
library.add(faPersonWalkingLuggage);
library.add(faUpDown);
library.add(faArrowsAltV);
library.add(faCloudMoonRain);
library.add(faCalendar);
library.add(faTrailer);
library.add(faBahai);
library.add(faHaykal);
library.add(faSdCard);
library.add(faDragon);
library.add(faShoePrints);
library.add(faCirclePlus);
library.add(faPlusCircle);
library.add(faFaceGrinTongueWink);
library.add(faGrinTongueWink);
library.add(faHandHolding);
library.add(faPlugCircleExclamation);
library.add(faLinkSlash);
library.add(faChainBroken);
library.add(faChainSlash);
library.add(faUnlink);
library.add(faClone);
library.add(faPersonWalkingArrowLoopLeft);
library.add(faArrowUpZA);
library.add(faSortAlphaUpAlt);
library.add(faFireFlameCurved);
library.add(faFireAlt);
library.add(faTornado);
library.add(faFileCirclePlus);
library.add(faBookQuran);
library.add(faQuran);
library.add(faAnchor);
library.add(faBorderAll);
library.add(faFaceAngry);
library.add(faAngry);
library.add(faCookieBite);
library.add(faArrowTrendDown);
library.add(faRss);
library.add(faFeed);
library.add(faDrawPolygon);
library.add(faScaleBalanced);
library.add(faBalanceScale);
library.add(faGaugeSimpleHigh);
library.add(faTachometer);
library.add(faTachometerFast);
library.add(faShower);
library.add(faDesktop);
library.add(faDesktopAlt);
library.add(faM);
library.add(faTableList);
library.add(faThList);
library.add(faCommentSms);
library.add(faSms);
library.add(faBook);
library.add(faUserPlus);
library.add(faCheck);
library.add(faBatteryThreeQuarters);
library.add(faBattery4);
library.add(faHouseCircleCheck);
library.add(faAngleLeft);
library.add(faDiagramSuccessor);
library.add(faTruckArrowRight);
library.add(faArrowsSplitUpAndLeft);
library.add(faHandFist);
library.add(faFistRaised);
library.add(faCloudMoon);
library.add(faBriefcase);
library.add(faPersonFalling);
library.add(faImagePortrait);
library.add(faPortrait);
library.add(faUserTag);
library.add(faRug);
library.add(faEarthEurope);
library.add(faGlobeEurope);
library.add(faCartFlatbedSuitcase);
library.add(faLuggageCart);
library.add(faRectangleXmark);
library.add(faRectangleTimes);
library.add(faTimesRectangle);
library.add(faWindowClose);
library.add(faBahtSign);
library.add(faBookOpen);
library.add(faBookJournalWhills);
library.add(faJournalWhills);
library.add(faHandcuffs);
library.add(faTriangleExclamation);
library.add(faExclamationTriangle);
library.add(faWarning);
library.add(faDatabase);
library.add(faShare);
library.add(faArrowTurnRight);
library.add(faMailForward);
library.add(faBottleDroplet);
library.add(faMaskFace);
library.add(faHillRockslide);
library.add(faRightLeft);
library.add(faExchangeAlt);
library.add(faPaperPlane);
library.add(faRoadCircleExclamation);
library.add(faDungeon);
library.add(faAlignRight);
library.add(faMoneyBill1Wave);
library.add(faMoneyBillWaveAlt);
library.add(faLifeRing);
library.add(faHands);
library.add(faSignLanguage);
library.add(faSigning);
library.add(faCalendarDay);
library.add(faWaterLadder);
library.add(faLadderWater);
library.add(faSwimmingPool);
library.add(faArrowsUpDown);
library.add(faArrowsV);
library.add(faFaceGrimace);
library.add(faGrimace);
library.add(faWheelchairMove);
library.add(faWheelchairAlt);
library.add(faTurnDown);
library.add(faLevelDownAlt);
library.add(faPersonWalkingArrowRight);
library.add(faSquareEnvelope);
library.add(faEnvelopeSquare);
library.add(faDice);
library.add(faBowlingBall);
library.add(faBrain);
library.add(faBandage);
library.add(faBandAid);
library.add(faCalendarMinus);
library.add(faCircleXmark);
library.add(faTimesCircle);
library.add(faXmarkCircle);
library.add(faGifts);
library.add(faHotel);
library.add(faEarthAsia);
library.add(faGlobeAsia);
library.add(faIdCardClip);
library.add(faIdCardAlt);
library.add(faMagnifyingGlassPlus);
library.add(faSearchPlus);
library.add(faThumbsUp);
library.add(faUserClock);
library.add(faHandDots);
library.add(faAllergies);
library.add(faFileInvoice);
library.add(faWindowMinimize);
library.add(faMugSaucer);
library.add(faCoffee);
library.add(faBrush);
library.add(faMask);
library.add(faMagnifyingGlassMinus);
library.add(faSearchMinus);
library.add(faRulerVertical);
library.add(faUserLarge);
library.add(faUserAlt);
library.add(faTrainTram);
library.add(faUserNurse);
library.add(faSyringe);
library.add(faCloudSun);
library.add(faStopwatch20);
library.add(faSquareFull);
library.add(faMagnet);
library.add(faJar);
library.add(faNoteSticky);
library.add(faStickyNote);
library.add(faBugSlash);
library.add(faArrowUpFromWaterPump);
library.add(faBone);
library.add(faUserInjured);
library.add(faFaceSadTear);
library.add(faSadTear);
library.add(faPlane);
library.add(faTentArrowsDown);
library.add(faExclamation);
library.add(faArrowsSpin);
library.add(faPrint);
library.add(faTurkishLiraSign);
library.add(faTry);
library.add(faTurkishLira);
library.add(faDollarSign);
library.add(faDollar);
library.add(faUsd);
library.add(faX);
library.add(faMagnifyingGlassDollar);
library.add(faSearchDollar);
library.add(faUsersGear);
library.add(faUsersCog);
library.add(faPersonMilitaryPointing);
library.add(faBuildingColumns);
library.add(faBank);
library.add(faInstitution);
library.add(faMuseum);
library.add(faUniversity);
library.add(faUmbrella);
library.add(faTrowel);
library.add(faD);
library.add(faStapler);
library.add(faMasksTheater);
library.add(faTheaterMasks);
library.add(faKipSign);
library.add(faHandPointLeft);
library.add(faHandshakeSimple);
library.add(faHandshakeAlt);
library.add(faJetFighter);
library.add(faFighterJet);
library.add(faSquareShareNodes);
library.add(faShareAltSquare);
library.add(faBarcode);
library.add(faPlusMinus);
library.add(faVideo);
library.add(faVideoCamera);
library.add(faGraduationCap);
library.add(faMortarBoard);
library.add(faHandHoldingMedical);
library.add(faPersonCircleCheck);
library.add(faTurnUp);
library.add(faLevelUpAlt);
// document.title = 'iCreateBot';
// eslint-disable-next-lin);
createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(store).use(router)
    .mount('#app');
