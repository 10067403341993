import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.layout)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout+'-layout'), {
        key: 0,
        title: _ctx.title,
        menu: _ctx.menu
      }, null, 8, ["title", "menu"]))
    : _createCommentVNode("", true)
}