<template>
  <div>      
  <head>
        <title>iCreateBot</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
  </head>
  <!-- <div class="icb_html"> -->
    <main-navbar></main-navbar>
  <icb_body class="icb_preload">
          <!-- <div class="icb_body"> -->
          <router-view />
          <main-footer></main-footer>
        </icb_body>
      </div>
      <!-- </div> -->
      <!-- </div> -->
  </template>
  <script>
  import '@/assets/default/css/bootstrap.min.css?7';
  import '@/assets/default/css/my.css?8';

  import MainNavbar from '@/components/main/MainNavbar.vue';
  import MainFooter from '@/components/main/MainFooter.vue';
//   import MainView from '@/views/main/MainView.vue';
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'TheDefaultLayout',
    components: {
      MainNavbar,
      MainFooter,
    //   MainView,
    },
    setup() {
      // ...так как возвращает объект
      // const router = useRouter();
      // const route = useRoute();
  
      // const pageTitle = computed(() => route.meta.title || 'Default Title');
  
      return {
        // pageTitle,
        // document.title = '';
        //   ...useLoginForm(),
        // navigate: (value) => { router.push(value); },
      };
    },
  });
  </script>
  