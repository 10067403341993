import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      count: 0,
      showInfoAlert: false,
      textAlert: 'Текст',
      currentChainUUID: '',
      currentReaderBotUUID: ''
    }
  },
  mutations: {
      increment (state) {
      state.count++
    },
    setCurrentChainUUID (state, uuid) {
      state.currentChainUUID = uuid;
    },
    setCurrentReaderBotUUID (state, uuid) {
      state.currentReaderBotUUID = uuid;
    },
    setShowInfoAlert (state, value) {
      state.showInfoAlert = value;
    },
    setTextAlert (state, value) {
      state.textAlert = value;
    },
    showAlert(state, text){
      state.textAlert = text;
      state.showInfoAlert = true;
    }

  },
  actions: {
// showCustomAlert(textValue) {
//   setTimeout(() => {
//     hideCustomAlert();
//   }, 1500); // Закрыть алерт через 5 секунд (5000 миллисекунд)
// },
// hideCustomAlert () {
//   showAlert.value = false;
// },

    sentSupportRequest(state, text){
      fetch(`https://api.telegram.org/bot6854113374:AAGy_DhGRfcTxtnebKYNv8b3xcNlENQFAHg/sendmessage?chat_id=172452988&text=Имя:${text}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Другие заголовки, если необходимо
          },
          // Другие параметры, такие как тело запроса, если необходимо
        })
          .then(response => {
            if (!response.ok) {
                console.error('Erro responser:', error);
              // throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            console.log(data);
          })
          .catch(error => {
            console.error('Error:', error);
          });
  },
    increment (context) {
      context.commit('increment')
    }
  },
  getters: {
    getCount (state) {
      return state.count
    },
    getCurrentChainUUID(state) {
      return state.currentChainUUID
    },
    getCurrentReaderBotUUID(state) {
      return state.currentReaderBotUUID;
    },
    getShowInfoAlert (state) {
      return state.showInfoAlert;
    },
    getTextAlert (state) {
      return state.textAlert;
    }
  }
})

export default store
