<template>
    <div class="icb_page_head icb_navbar icb_navbar-static-top icb_navbar-icb">
        <div class="icb_navbar-inner">
            <div class="icb_container icb_clearfix">
                <ul class="icb_nav icb_navbar-nav icb_navbar-right">                    
                        <li :class="{ 'navbar-help-icb hidden-xs active': route.name === 'start-web','navbar-help-icb hidden-xs': route.name !== 'start-web' }" 
                        v-if="route.name === 'start-web' || route.name === 'start-android'  || route.name === 'start-iphone' || route.name === 'start-windows'">
                            <router-link to="/start-web">
                                <a>Web</a>
                            </router-link>
                        </li>
                        <li :class="{ 'navbar-help-icb hidden-xs active': route.name === 'start-android','navbar-help-icb hidden-xs': route.name !== 'start-android' }" 
                        v-if="route.name === 'start-web' || route.name === 'start-android'  || route.name === 'start-iphone' || route.name === 'start-windows'">
                            <router-link to="/start-android">
                                <a>Android</a>
                            </router-link>
                        </li>
                        <li :class="{ 'navbar-help-icb hidden-xs active': route.name === 'start-iphone','navbar-help-icb hidden-xs': route.name !== 'start-iphone' }" 
                        v-if="route.name === 'start-web' || route.name === 'start-android'  || route.name === 'start-iphone' || route.name === 'start-windows'">
                            <router-link to="/start-iphone">
                                <a>iPhone</a>
                            </router-link>
                        </li>
                        <li :class="{ 'navbar-help-icb hidden-xs active': route.name === 'start-windows','navbar-help-icb hidden-xs': route.name !== 'start-windows' }" 
                        v-if="route.name === 'start-web' || route.name === 'start-android'  || route.name === 'start-iphone' || route.name === 'start-windows'">
                            <router-link to="/start-windows">
                                <a>Windows | Mac | Linux</a>
                            </router-link>
                        </li>
                        <li :class="{ 'navbar-help-icb hidden-xs active': route.name === 'support',
                                  'navbar-help-icb hidden-xs': route.name !== 'support' }">
                        <router-link to="/support">
                            <a>Написать в поддержку</a>
                        </router-link>
                    </li>
                </ul>
                <ul class="icb_nav icb_navbar-nav">
                    <li :class="{ 'active': route.name === 'home' }">
                        <router-link to="/">
                            <a>Главная</a>
                        </router-link>
                    </li>
                    <li :class="{ 'active': route.name === 'customers' }">
                        <router-link to="/customers">
                            <a>Для кого</a>
                        </router-link>
                    </li>
                    <li :class="{ 'active': route.name === 'start-web' || route.name === 'start-android' || route.name === 'start-iphone' || route.name === 'start-windows'}">
                    <!-- <li :class="{ 'active': route.name === 'start-web', 'active': route.name === 'start-android', 'active': route.name === 'start-iphone', 'active': route.name === 'start-windows' }"> -->
                        <router-link to="/start-web">
                            <a>С чего начать</a>
                        </router-link>
                    </li>
                    <li :class="{ 'active': route.name === 'plans' }">
                        <router-link to="/plans">
                            <a>Цены</a>
                        </router-link>
                    </li>
                </ul>
          </div>
        </div>
      </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();

// import {
//   defineProps} from 'vue';

// const props = defineProps({
//   currentMenu: String,
//   listActions: Object,
//   addFieldList: Object,
//   listUserGroup: Object,
// });


    // const store = useStore();
    // function logout() {
    //   store.commit('auth/logout');
    //   router.push('/');
    // }
    // function scrollToAnchorPoint(refName) {
    //   console.log(refName);
    //   const el = this.$refs[refName];
    //   console.log('el');
    //   console.log(el);

    //   el.scrollIntoView({ behavior: 'smooth' });
    // }



</script>
