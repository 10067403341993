<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

  <!-- Sidebar - Brand -->
  <!-- <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
      <div class="sidebar-brand-icon rotate-n-15">
          <i class="fas fa-laugh-wink"></i>
      </div>
      <div class="sidebar-brand-text mx-3">На главную</div>
  </a> -->

  <!-- Divider -->
  <hr class="sidebar-divider my-0">

  <!-- Nav Item - Dashboard -->
  <li class="nav-item">
      <router-link class="nav-link" to="/"><i class="fas fa-fw fa-compass"></i>
          <span>Главная</span></router-link>
  </li>

  <!-- Divider -->
  <hr class="sidebar-divider">

  <!-- Heading -->
  <!-- <div class="sidebar-heading">
      Interface
  </div> -->

  <!-- Nav Item - Pages Collapse Menu -->
  <!-- <li class="nav-item active" v-for="item of menu" :key="item"> -->
  <li :class="{'nav-item':true, 'active':item.selected}"
   v-for="item of menu" :key="item">
      <a class="nav-link" href="#" data-toggle="collapse"
      :data-target="'#'+item.name" aria-expanded="true"
          aria-controls="collapseTwo">
          <i class="fas fa-fw fa-cog"></i>
          <!-- <span>Боты</span> -->
          <span>{{ item.title}}</span>
      </a>
      <div :id="item.name" class="collapse show" aria-labelledby="headingTwo"
          data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
              <!-- <h6 class="collapse-header">Custom Components:</h6> -->
  <router-link v-for="(innerItem, index) of item.items" :key="innerItem"
              class="collapse-item"
              :to="item.items[index].link">
              {{innerItem.name}}</router-link>

              <!-- <a class="collapse-item active" href="cards.html">Аналитика</a> -->
          </div>
      </div>
  </li>

  <!-- Nav Item - Utilities Collapse Menu -->
  <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
          aria-expanded="true" aria-controls="collapseUtilities">
          <i class="fas fa-fw fa-wrench"></i>
          <span>Читатели</span>
      </a>
      <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
          data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
               <h6 class="collapse-header">Custom Utilities:</h6> -->
              <!-- <a class="collapse-item" href="utilities-color.html">Все</a> -->
              <!-- <a class="collapse-item" href="utilities-border.html">Новые</a> -->
              <!-- <a class="collapse-item" href="utilities-animation.html">Аналитика</a> -->
              <!-- <a class="collapse-item" href="utilities-other.html">Заблокированные</a> -->
          <!-- </div>
      </div>
  </li> -->

  <!-- <hr class="sidebar-divider"> -->

  <!-- Heading -->
  <!-- <div class="sidebar-heading">
      Addons
  </div> -->

  <!-- Nav Item - Pages Collapse Menu -->
  <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
          aria-expanded="true" aria-controls="collapsePages">
          <i class="fas fa-fw fa-folder"></i>
          <span>Pages</span>
      </a>
      <div id="collapsePages" class="collapse" aria-labelledby="headingPages"
      data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded"> -->
              <!-- <h6 class="collapse-header">Login Screens:</h6> -->
              <!-- <a class="collapse-item" href="login.html">Login</a> -->
              <!-- <a class="collapse-item" href="register.html">Register</a> -->
              <!-- <a class="collapse-item" href="forgot-password.html">Forgot Password</a> -->
              <!-- <div class="collapse-divider"></div> -->
              <!-- <h6 class="collapse-header">Other Pages:</h6> -->
              <!-- <a class="collapse-item" href="404.html">404 Page</a> -->
              <!-- <a class="collapse-item" href="blank.html">Blank</a>
          </div>
      </div>
  </li> -->

  <!-- <li class="nav-item">
      <a class="nav-link" href="charts.html">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Blank</span></a>
  </li>

  <li class="nav-item">
      <a class="nav-link" href="tables.html">
          <i class="fas fa-fw fa-table"></i>
          <span>Blank</span></a>
  </li>
  <li class="nav-item">
      <a class="nav-link" href="charts.html">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Blank</span></a>
  </li>

  <li class="nav-item">
      <a class="nav-link" href="tables.html">
          <i class="fas fa-fw fa-table"></i>
          <span>Blank</span></a>
  </li>
  <li class="nav-item">
      <a class="nav-link" href="charts.html">
          <i class="fas fa-fw fa-chart-area"></i>
          <span>Blank</span></a>
  </li>

  <li class="nav-item">
      <a class="nav-link" href="tables.html">
          <i class="fas fa-fw fa-table"></i>
          <span>Blank</span></a>
  </li> -->

  <!-- Divider -->
  <!-- <hr class="sidebar-divider d-none d-md-block"> -->

  <!-- Sidebar Toggler (Sidebar) -->
  <!-- <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
  </div> -->

  </ul>
  </template>

<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
// import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const route = useRoute();
    console.log(route);
    const menu = [{
      id: 1,
      title: 'Боты',
      name: 'bots',
      selected: true,
      items: [{ id: 1, name: 'Сервисный бот', link: '/dashboardmaster/service-bot' },
        //   { id: 2, name: 'Читательские боты', link: '/dashboard/reader-bots' },
        // { id: 3, name: 'Аналитика', link: '/dashboard/service-bot' },
        //   { id: 4, name: 'Загрузка файлов', link: '/dashboard/upload-file' },
        // { id: 5, name: 'Настройка цепочки', link: '/dashboard/edit-chain' },
      ],
    },
    {
      id: 2,
      title: 'Пользователи',
      name: 'users',
      selected: false,
      items: [{ id: 1, name: 'Все', link: '/dashboardmaster/list-users' },
        { id: 2, name: 'Новые', link: '/dashboard/service-bot' },
        { id: 3, name: 'Заблокированные', link: '/dashboard/service-bot' },
        { id: 4, name: 'Аналитика', link: '/dashboard/service-bot' }],
    },
    ];

    return {
      menu,
      route,
      //   router,
    };
  },

  //   methods: {
  //     showMenu() {
  //       console.log(menu);
  //     },
  //   },
  //   props: ['menu'],
  // },
  //   props: {
  //     // menu: String,
  //     menu: Array,
  //   },
});
</script>
