<template>
  <div id="wrapper">
    <dashboard-sidebar :menu="menu"></dashboard-sidebar>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <dashboard-navbar :title="title" :menu="menu"></dashboard-navbar>
            <div class="container-fluid">
              <Suspense>
                <router-view></router-view>
              </Suspense>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import '@/assets/dashboard/css/sb-admin-2.css';
import '@/assets/dashboard/css/sb-admin-2.min.css';
import '@/assets/dashboard/vendor/jquery/jquery';
// import '@/assets/dashboard/vendor/jquery/jquery.min';
// import '@/assets/dashboard/vendor/jquery/jquery';

// import '@/assets/dashboard/js/sb-admin-2';
// import '@/assets/dashboard/js/sb-admin-2.min';
import '@/assets/dashboard/vendor/fontawesome-free/css/all.min.css';
import '@/assets/dashboard/vendor/bootstrap/js/bootstrap.bundle.min';

import '@/assets/dashboard/vendor/jquery-easing/jquery.easing.min';

import DashboardSidebar from '@/components/dashboard/DashboardSidebar.vue';
// import DashboardSidebar1 from '@/components/dashboard/DashboardSidebar1.vue';
import DashboardNavbar from '@/components/dashboard/DashboardNavbar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheDashboardLayout',
  components: {
    // MainNavbar,
    DashboardSidebar,
    // DashboardSidebar1,
    DashboardNavbar,
  },
  props: ['title', 'menu'],
});

</script>
