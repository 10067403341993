
import { defineComponent } from 'vue';
import useBots from '@/use/bots';
// import { useRouter } from 'vue-router';

export default defineComponent({
  async setup() {
    const { bots } = await useBots();
    // bots.value?.map(bot=>{
    //   bot.id
    // })
    //   const router = useRouter();
    return {
      bots,
    };
  },
});

