
import { defineComponent, onErrorCaptured, ref } from 'vue';
import ListBot from '@/components/ListBot.vue';

export default defineComponent({
  components: { ListBot },
  setup() {
    const error = ref<null|unknown>(null);

    onErrorCaptured((e) => {
      error.value = e;
      return true;
    });
    //   const router = useRouter();
    return {
      error,
      //     navigate: () => { router.push('/about'); },
    };
  },
});

