<template>
    <div>
        <div class="footer_wrap">
  <div class="footer_columns_wrap footer_desktop">
    <div class="footer_column footer_column_telegram">
      <h4>iCreateBot</h4>
      <div class="footer_telegram_description"></div>
      <h5>
          система для настройки Telegram ботов.
      </h5>
    </div>

    <div class="footer_column">
      <h5>
        <router-link to="/">
            <a>Главная</a>
          </router-link></h5>
      <ul>
        <li>
          <router-link to="/safety">
            <a>Безопасность</a>
          </router-link></li>
        <!-- <li>
          <router-link to="/safety">
            <a>Обработка данных</a>
          </router-link></li> -->
        <li>
          <router-link to="/">
            <a>Уроки</a>
          </router-link></li>
      </ul>
    </div>
    <!-- <div class="footer_column">
      <h5><a href="/apps#mobile-apps">Для мобильных</a></h5>
      <ul>
        <li><a href="/dl/ios">iPhone/iPad</a></li>
        <li><a href="/android">Android</a></li>
        <li><a href="/dl/web">Мобильная веб-версия</a></li>
      </ul>
    </div> -->
   <!-- <div class="footer_column">
      <h5><a href="/apps#desktop-apps">Для компьютера</a></h5>
      <ul>
        <li><a href="//desktop.telegram.org/">PC/Mac/Linux</a></li>
        <li><a href="//macos.telegram.org/">macOS</a></li>
        <li><a href="/dl/web">Веб-версия</a></li>
      </ul>
    </div> -->
    <div class="footer_column footer_column_platform">
      <h5>
        <router-link to="/">
            <a>Разработка</a>
          </router-link></h5>
      <ul>
        <!-- <li>
          <router-link to="/safety">
            <a>Примеры ботов</a>
          </router-link></li> -->
        <li>
          <router-link to="/plans">
            <a>Тарифы</a>
          </router-link></li>
        <li>
          <router-link to="/support">
            <a>Техническая поддержка</a>
          </router-link></li>
      </ul>
    </div>
  </div>
  <!-- <div class="footer_columns_wrap footer_mobile">
    <div class="footer_column">
      <h5><a href="/faq">Об iCreateBot</a></h5>
    </div>
    <div class="footer_column">
      <h5><a href="/blog">Блог</a></h5>
    </div>
    <div class="footer_column">
      <h5><a href="/apps">Приложения</a></h5>
    </div>
    <div class="footer_column">
      <h5><a href="/">Тестирование</a></h5>
    </div>
    <div class="footer_column">
      <h5><a href="/">Примеры ботов</a></h5>
    </div>
  </div> -->
</div>
    </div>    
        </template>
        
        <script>
        import { useLoginForm } from '@/use/login-form';
        import { defineComponent } from 'vue';
        export default defineComponent({
          setup() {
            // ...так как возвращает объект
            // const router = useRouter();
        
            return {
              ...useLoginForm(),
            //   navigate: () => { router.push('/contact'); },
            };
          },
        });
</script>
        