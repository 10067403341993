import { ref, Ref } from 'vue';
import useFetch from '@/use/fetch';

interface Bot {
    id:number;
    name:string;
    email:string;
}

type UsableUsers = Promise<{bots:Ref<Bot[]|undefined>}>;

export default async function useBots(): UsableUsers {
  const loading:Ref<boolean> = ref<boolean>(false);
  const { response: bots, request } = useFetch<Bot[]>('https://jsonplaceholder.typicode.com/users');

  if (!loading.value) {
    await request();
    loading.value = true;
  }

  return { bots };
}
