import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import HomeView from '../views/HomeView.vue';
import BotsView from '../views/BotsView.vue';
// import store from '../store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // component: HomeView,
    component: () => import('../views/main/MainView.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  // {
  //   path: '/start-guide',
  //   name: 'start-guide',
  //   component: () => import('../views/main/StartGuide.vue'),
  //   meta: {
  //     layout: 'main',
  //     auth: false,
  //     title: 'iCreateBot',
  //   },
  // },
  {
    path: '/start-web',
    name: 'start-web',
    component: () => import('../views/main/GuideWeb.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    path: '/start-android',
    name: 'start-android',
    component: () => import('../views/main/GuideAndroid.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    path: '/start-iphone',
    name: 'start-iphone',
    component: () => import('../views/main/GuideIphone.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    path: '/start-windows',
    name: 'start-windows',
    component: () => import('../views/main/GuideDesktop.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/main/CustomersView.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/main/SupportView.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    path: '/safety',
    name: 'safety',
    component: () => import('../views/main/SafetyView.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    path: '/plans',
    name: 'plans',
    component: () => import('../views/main/PlansView.vue'),
    meta: {
      layout: 'main',
      auth: false,
      title: 'iCreateBot',
    },
  },
  {
    // history: 'createWebHistory',
    path: '/login/:uuid',
    name: 'login',
    // component: HomeView,
    component: () => import('../views/LoginView.vue'),
    meta: {
      layout: 'main',
      title: 'iCreateBot',
      auth: false,
    },
  },
  {
    path: '/bad-login',
    name: 'bad-login',
    // component: HomeView,
    component: () => import('../views/BadLoginView.vue'),
    meta: {
      layout: 'main',
      title: 'iCreateBot',
      auth: false,
    },
  },
  {
    path: '/bots',
    name: 'bots',
    component: BotsView,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue'),
    meta: {
      layout: 'dashboard',
      title: 'iCreateBot',
      // title: 'Панель управления',
      auth: true,
    },
  },
  {
    path: '/dashboard/edit-chain/:id',
    name: 'edit-chain',
    component: () => import('../views/dashboard/EditChain.vue'),
    meta: {
      layout: 'dashboard',
      // layout: 'main',
      title: 'iCreateBot',
      // title: 'Настройка цепочки',
      auth: true,
    },
  },
  {
    path: '/dashboard/service-bot',
    name: 'dashboard-service-bot',
    component: () => import('../views/dashboard/ServiceBot.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Сервисный бот',
      auth: true,
    },
  },
  {
    path: '/dashboard/upload-file',
    name: 'upload-file',
    component: () => import('../views/dashboard/UploadFile.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Загрузка файла',
      auth: true,
    },
  },
  {
    path: '/dashboard/reader-bots',
    name: 'dashboard-reader-bots',
    component: () => import('../views/dashboard/ReaderBots.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Боты читателей',
      auth: true,
    },
  },
  {
    path: '/dashboard/reader-bot-list-chain',
    // path: '/dashboard/reader-bot-list-chain/:id',
    name: 'dashboard-reader-bot-list-chain',
    component: () => import('../views/dashboard/ReaderBotListChain.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Список цепочек',
      auth: true,
    },
  },
  {
    path: '/dashboard/reader-bot-list-reader-group',
    name: 'dashboard-reader-bot-list-reader-group',
    component: () => import('../views/dashboard/ReaderBotListReaderGroup.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Группы читателей',
      auth: true,
    },
  },
  {
    path: '/dashboard/reader-bot-team',
    name: 'dashboard-reader-bot-team',
    component: () => import('../views/dashboard/ReaderBotTeam.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Команда',
      auth: true,
    },
  },
  {
    path: '/dashboard/links-chain/:id',
    name: 'dashboard-links-chain',
    component: () => import('../views/dashboard/LinksChain.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Настройка ссылок на цепочку',
      auth: true,
    },
  },
  {
    path: '/dashboard/list-raders-all',
    name: 'dashboard-list-raders-all',
    component: () => import('../views/dashboard/ListReadersAll.vue'),
    meta: {
      layout: 'dashboard',
      title: 'Читатели',
      auth: true,
    },
  },
  // ------------MASTER BLOCK--------------------------------------------------------------
  // page master service bot
  {
    path: '/dashboardmaster/service-bot',
    name: 'dashboardmaster-service-bot',

    component: () => import('../views/dashboardmaster/MasterServiceBot.vue'),
    meta: {
      layout: 'master',
      title: 'Панель администрирования',
      auth: true,
    },
  },
  // page list users
  {
    path: '/dashboardmaster/list-users',
    name: 'dashboardmaster-list-users',
    component: () => import('../views/dashboardmaster/listUsers.vue'),
    meta: {
      layout: 'master',
      title: 'Список пользователей',
      auth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    // component: () => import('../views/dashboard/ReaderBots.vue'),
    component: () => import('../views/main/NotFound.vue'),
    // component: () => import('../views/dashboard/ServiceBot.vue'),
    meta: {
      // layout: 'dashboard',
      layout: 'main',
      title: 'Стриница не найдена',
      auth: true,
    },
  },
  {
    path: '/dashboardmaster/user/:uuid',
    name: 'dashboard-links-chain',
    component: () => import('../views/dashboardmaster/userView.vue'),
    meta: {
      layout: 'master',
      title: 'Пользователь',
      auth: true,
    },
  },
  {
    path: '/dashboardmaster/user-service-bot/:uuid',
    name: 'dashboard-servise-bot-user',
    component: () => import('../views/dashboardmaster/serviceBotUser.vue'),
    meta: {
      layout: 'master',
      title: 'Сервисный бот пользователя',
      auth: true,
    },
  },
  {
    path: '/dashboardmaster/user-reader-bot/:uuid',
    name: 'dashboard-reader-bot-user',
    component: () => import('../views/dashboardmaster/readerBotUser.vue'),
    meta: {
      layout: 'master',
      title: 'Читательский бот пользователя',
      auth: true,
    },
  },
  {
    path: '/dashboardmaster/edit-chain/:uuid',
    name: 'dashboard-edit-chain',
    component: () => import('../views/dashboardmaster/EditChain.vue'),
    meta: {
      layout: 'master',
      title: 'Цепочка читательского бота',
      auth: true,
    },
  },
  //---------------------------------------------------------------------------------------
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      layout: 'main',
      auth: false,
    },
  },
  {
    path: '/error',
    name: 'about',
    component: () => import('../views/InnerError.vue'),
    meta: {
      layout: 'main',
      auth: false,
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      layout: 'main',
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  // linkActiveClass: 'active',
  // linkExactActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
  // console.log(`to.meta.auth ${to.meta.auth}, to.path = ${to.path}`);
  // console.log(`beforeEach ${localStorage.getItem('jwt-token')}`);
  document.title = 'iCreateBot';
  const token = !!localStorage.getItem('jwt-token');
  const requireAuth = to.meta.auth;
  // если мы требуем авторизацию и эта авторизация есть то продолжаем
  // if (requireAuth && store.getters['auth/isAuthenticated']) {
  //   console.log('нужна авторизация и есть токен');
  //   next();
  // } else if (requireAuth && !store.getters['auth/isAuthenticated']) {
  //   console.log('нужна авторизация и нет токена');
  //   //   console.log('requireAuth && !store.getters[\'auth/isAuthenticated\']');

  //   next('/bad-login');
  //   //   // next('/login?message=auth');
  // } else {
  //   next();
  // }
  if (requireAuth) {
    if (token) {
      // if (store.getters['auth/isAuthenticated']) {
      // console.log(`to.path = ${to.path}`);
      // console.log('нужна авторизация и есть токен');
      next();
    } else {
      console.log(`to.path = ${to.path}`);
      console.log(`token = ${token}`);
      console.log('нужна авторизация и нет токена');
      next('/bad-login');
    }
  } else {
    next();
  }
});

export default router;
