<template>
  <!-- <ul class="navbar-nav sidebar sidebar-dark" > -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

<!-- Sidebar - Brand -->
<!-- <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
    <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-laugh-wink"></i>
    </div>
    <div class="sidebar-brand-text mx-3">На главную</div>
</a> -->

<!-- Divider -->
<!-- <hr class="sidebar-divider my-0"> -->
<li class="nav-item">
    <router-link class="nav-link" to="/"><i class="fas fa-fw fa-home"></i>
        <span>Главная</span>
    </router-link>
</li>

<!-- Divider -->
<hr class="sidebar-divider">

<!-- Heading -->
<!-- <div class="sidebar-heading">
    Interface
</div> -->

<!-- Nav Item - Pages Collapse Menu -->
<!-- <li class="nav-item active" v-for="item of menu" :key="item"> -->
<li :class="{'nav-item':true, 'active':item.selected}"
 v-for="item of menu" :key="item">
    <a class="nav-link" href="#" data-toggle="collapse"
    :data-target="'#'+item.name" 
    aria-expanded="true"
        aria-controls="collapseTwo"
        >
        <i class="fas fa-fw fa-cog"></i>
        <!-- <span>Боты</span> -->
        <span>{{ item.title}}</span>
    </a>
    <div :id="item.name" class="collapse show" aria-labelledby="headingTwo"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
             <!-- <h6 class="collapse-header">Custom Components:</h6> -->
<router-link v-for="(innerItem, index) of item.items" :key="innerItem"
            class="collapse-item"
            :to="item.items[index].link">
            {{innerItem.name}}</router-link> 

            <!-- <a class="collapse-item active" href="cards.html">Аналитика</a> -->
        </div>
    </div>
</li>

<!-- Nav Item - Utilities Collapse Menu -->
<!-- <li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <i class="fas fa-fw fa-wrench"></i>
        <span>Читатели</span>
    </a>
    <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
        data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
             <h6 class="collapse-header">Custom Utilities:</h6> -->
            <!-- <a class="collapse-item" href="utilities-color.html">Все</a> -->
            <!-- <a class="collapse-item" href="utilities-border.html">Новые</a> -->
            <!-- <a class="collapse-item" href="utilities-animation.html">Аналитика</a> -->
            <!-- <a class="collapse-item" href="utilities-other.html">Заблокированные</a> -->
        <!-- </div>
    </div>
</li> -->

<!-- <hr class="sidebar-divider"> -->

<!-- Heading -->
<!-- <div class="sidebar-heading">
    Addons
</div> -->

<!-- Nav Item - Pages Collapse Menu -->
<!-- <li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
        aria-expanded="true" aria-controls="collapsePages">
        <i class="fas fa-fw fa-folder"></i>
        <span>Pages</span>
    </a>
    <div id="collapsePages" class="collapse" aria-labelledby="headingPages"
    data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded"> -->
            <!-- <h6 class="collapse-header">Login Screens:</h6> -->
            <!-- <a class="collapse-item" href="login.html">Login</a> -->
            <!-- <a class="collapse-item" href="register.html">Register</a> -->
            <!-- <a class="collapse-item" href="forgot-password.html">Forgot Password</a> -->
            <!-- <div class="collapse-divider"></div> -->
            <!-- <h6 class="collapse-header">Other Pages:</h6> -->
            <!-- <a class="collapse-item" href="404.html">404 Page</a> -->
            <!-- <a class="collapse-item" href="blank.html">Blank</a>
        </div>
    </div>
</li> -->

<!-- <li class="nav-item">
    <a class="nav-link" href="charts.html">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Blank</span></a>
</li>

<li class="nav-item">
    <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Blank</span></a>
</li>
<li class="nav-item">
    <a class="nav-link" href="charts.html">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Blank</span></a>
</li>

<li class="nav-item">
    <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Blank</span></a>
</li>
<li class="nav-item">
    <a class="nav-link" href="charts.html">
        <i class="fas fa-fw fa-chart-area"></i>
        <span>Blank</span></a>
</li>

<li class="nav-item">
    <a class="nav-link" href="tables.html">
        <i class="fas fa-fw fa-table"></i>
        <span>Blank</span></a>
</li> -->

<!-- Divider -->
<!-- <hr class="sidebar-divider d-none d-md-block"> -->

<!-- Sidebar Toggler (Sidebar) -->
<!-- <div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle"></button>
</div> -->

</ul>
<!-- =================================================================== -->




  <!-- <div class="d-flex flex-column flex-shrink-0 bg-light custom-sidebar">
    <a href="/" class="d-block p-home custom-tooltip"> -->
    <!-- <a href="/" class="d-block p-3 link-dark text-decoration-none" title="Icon-only" data-bs-toggle="tooltip" data-bs-placement="right"> -->
      <!-- <svg class="bi" width="40" height="32"><use xlink:href="#bootstrap"/></svg> -->
      <!-- <span class="visually-hidden">Icon-only</span> -->
      <!-- <i class="fas fa-fw fa-table sidebar-icon"></i> -->
      <!-- <i class="fas fa-fw fa-home sidebar-icon"></i> -->
    <!-- </a>
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
        <li class="nav-item">
            <a href="#" class="nav-link active p-home border-bottom"> -->
            <!-- <a href="#" class="nav-link active p-home border-bottom" aria-current="page" title="Home" data-bs-toggle="tooltip" data-bs-placement="right"> -->
            <!-- <i class="fas fa-fw fa-at sidebar-icon"></i> -->
            <!-- <i class="fas fa-fw fa-robot sidebar-icon"></i> -->
            <!-- <svg class="bi" width="24" height="24" role="img" aria-label="Home"><use xlink:href="#home"/></svg> -->
        <!-- </a>
    </li>
    <li>
        <a href="#" class="nav-link p-home border-bottom" title="Dashboard" data-bs-toggle="tooltip" data-bs-placement="right">
            <i class="fas fa-fw fa-users sidebar-icon"></i> -->
            <!-- <svg class="bi" width="24" height="24" role="img" aria-label="Dashboard"><use xlink:href="#speedometer2"/></svg> -->
        <!-- </a>
    </li>
    <li>
        <a href="#" class="nav-link p-home border-bottom" title="Orders" data-bs-toggle="tooltip" data-bs-placement="right">
            <i class="fas fa-fw fa-upload sidebar-icon"></i> -->
            <!-- <svg class="bi" width="24" height="24" role="img" aria-label="Orders"><use xlink:href="#table"/></svg> -->
        <!-- </a>
    </li>
    <li>
        <a href="#" class="nav-link p-home border-bottom" title="Products" data-bs-toggle="tooltip" data-bs-placement="right">
            <i class="fas fa-fw fa-money-bill sidebar-icon"></i> -->
            <!-- <svg class="bi" width="24" height="24" role="img" aria-label="Products"><use xlink:href="#grid"/></svg> -->
        <!-- </a>
    </li>
    <li>
        <a href="#" class="nav-link p-home border-bottom" title="Customers" data-bs-toggle="tooltip" data-bs-placement="right">
            <i class="fas fa-fw fa-table sidebar-icon"></i> -->
          <!-- <svg class="bi" width="24" height="24" role="img" aria-label="Customers"><use xlink:href="#people-circle"/></svg> -->
        <!-- </a>
      </li>
    </ul>
  </div> -->
</template>

<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
// import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const route = useRoute();
    console.log(route);
    const menu = [{
      id: 1,
      title: 'Боты',
      name: 'bots',
      selected: true,
      items: [{ id: 1, name: 'Сервисный бот', link: '/dashboard/service-bot' },
        { id: 2, name: 'Читательские боты', link: '/dashboard/reader-bots' },
        // { id: 3, name: 'Аналитика', link: '/dashboard/service-bot' },
        { id: 4, name: 'Загрузка файлов', link: '/dashboard/upload-file' },
        // { id: 5, name: 'Настройка цепочки', link: '/dashboard/edit-chain' },
      ],
    },
    {
      id: 2,
      title: 'Читатели',
      name: 'readers',
      selected: true,
      items: [{ id: 1, name: 'Все', link: '/dashboard/list-raders-all' },
        { id: 2, name: 'Новые', link: '/dashboard/list-raders-all' },
        { id: 3, name: 'Заблокированные', link: '/dashboard/list-raders-all' },
        { id: 4, name: 'Аналитика', link: '/dashboard/list-raders-all' }],
    },
    // {
    //   id: 3,
    //   title: 'Команда',
    //   name: 'team',
    //   selected: true,
    //   items: [{ id: 1, name: 'Члены', link: '/dashboard/list-raders-all' },
    //     { id: 2, name: 'Должости', link: '/dashboard/list-raders-all' },
    // ],
    // },
    {
      id: 3,
      title: 'Тариф и оплата',
      name: 'plans',
      selected: true,
      items: [{ id: 1, name: 'Тариф', link: '/dashboard/plan' },
        { id: 2, name: 'Платежи', link: '/dashboard/payments' },
    ],
    },
    ];

    return {
      menu,
      route,
    //   router,
    };
  },

  //   methods: {
  //     showMenu() {
  //       console.log(menu);
  //     },
  //   },
  //   props: ['menu'],
  // },
//   props: {
//     // menu: String,
//     menu: Array,
//   },
});
</script>


<style>
    .dash-sidebar {
      height: 100%;
      width: 250px;
      position: fixed;
      top: 0;
      left: 0;
      /* background-color: red; */
    }
    .dash-sidebar-item {
      padding: 10px;
      display: flex;
      align-items: center;
    }

    .sidebar-icon{
  font-size: 24px;
}


.custom-tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
}

/* Tooltip text */
.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.custom-tooltip .custom-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.custom-sidebar{
    /* background-color: red; */
    width: 4.5rem;
    padding-left: 0;
}
.custom-inner-sidebar{
    /* background-color: red; */
    width: 4.5rem;
    padding-left: 4.5rem;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
  opacity: 1;
}

  </style>
